//
// 아이콘 버튼
// --------------------------------------------------

.btn-ico-del {
	display: inline-block;
	border: 1px solid #232323;
	vertical-align: middle;
	position: relative;
	top: -1px;

	@include afterSpriteIR-2x($ico-small-del,13px,13px,relative);
}