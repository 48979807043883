//
// 주메뉴
// --------------------------------------------------

// 메뉴 오픈시 본문 고정
.menu-open {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	overflow: hidden;
}

.gnb-nav {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background: #fff;
	position: fixed;
	top: 0;
	left: -100%;
	z-index: 9999;
	transition: left .2s;

	.menu-open & {
		left: 0;
	}

	.nav-head {
		flex-shrink: 0;
		box-sizing: border-box;
		height: 110px;
		padding: 0 25px;
		padding: 0 safe(left,25px);
		color: #fff;
		background: #232323;
		position: relative;
	}

	.btn-menu-close {
		display: block;
		top: 0;
		right: 0;
		@include afterSpriteIR-2x($ico-gnb-close,45px,45px,absolute);
	}

	.head-util {
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 55px;
		padding: 15px 25px 0 0;
		font-weight: 500;
		font-size: 12px;

		.link {
			font-size: 0;

			a {
				padding: 0 12px;
				font-size: 10px;

				&:first-child { padding-left: 0; }
				&:last-child { padding-right: 0; }

				&:not(:last-child):after {
					content: '';
					display: inline-block;
					width: 1px;
					height: 10px;
					vertical-align: middle;
					background: #7d7d7d;
					position: relative;
					top: -1px;
					right: -12px;
				}
			}
		}

		.info,
		.join {
			&:before {
				content: '';
				display: inline-block;
				margin-right: 4px;
				vertical-align: middle;
				position: relative;
				top: -2px;
				@include sprite-2x($ico-gnb-member);
			}
		}

		.change {
			&:before {
				content: '';
				display: inline-block;
				margin-right: 4px;
				vertical-align: middle;
				position: relative;
				top: -1px;
				@include sprite-2x($ico-gnb-change);
			}
		}

		.confirm {
			&:before {
				content: '';
				display: inline-block;
				margin-right: 4px;
				vertical-align: middle;
				position: relative;
				top: -1px;
				@include sprite-2x($ico-gnb-confirm);
			}
		}

		.login,
		.logout {
			&:before {
				content: '';
				display: inline-block;
				margin-right: 4px;
				vertical-align: middle;
				position: relative;
				top: -1px;
				@include sprite-2x($ico-gnb-login);
			}
		}
	}

	.tab-nav {
		display: flex;
		border: 1px solid #fff;

		.tab {
			flex: 1;
			display: flex;
			box-sizing: border-box;
			height: 38px;
			line-height: 38px;
			text-align: center;
			font-weight: 500;
			font-size: 14px;
			color: #fff;

			&:not(:first-child) {
				border-left: 1px solid #fff;
			}

			&.selected {
				color: #232323;
				background: #fff;
			}
		}

		a {
			flex: 1;
			text-decoration: none;
		}
	}
	
	.nav-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: auto;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
	}

	.tab-con {
		flex: 1;
		padding: 15px 25px 30px;
		padding: 15px safe(left,25px) 30px;
	}

	.nav {
		> li {
			border-bottom: 1px solid #eaeaea;

			&.has-group .mm:after {
				content: '';
				display: block;
				position: absolute;
				top: calc(50% - 6px);
				right: 6px;
				@include sprite-2x($ico-gnb-plus);
			}

			&.has-group.on .mm:after {
				@include sprite-2x($ico-gnb-minus);
			}
		}
	}

	.sub {
		display: none;
		padding: 5px 0;
		background: #f2f2f2;
		border-top: 1px solid #aaa;
	}

	.mm {
		box-sizing: border-box;
		display: block;
		height: 42px;
		line-height: 42px;
		padding: 0 30px 0 5px;
		font-weight: 500;
		font-size: 14px;
		color: #232323;
		text-decoration: none;
		position: relative;

		&.has-ico {
			padding-left: 35px;

			&:before {
				content: '';
				display: block;
				width: 25px;
				height: 25px;
				position: absolute;
				top: calc(50% - 12px);
				left: 0;
			}
		}

		&.guide:before { @include sprite-2x($ico-gnb-ico-guide); }
		&.room:before { @include sprite-2x($ico-gnb-ico-room); }
		&.eating:before { @include sprite-2x($ico-gnb-ico-eating); }
		&.waterpark:before { @include sprite-2x($ico-gnb-ico-waterpark); }
		&.golf:before { @include sprite-2x($ico-gnb-ico-golf); }
		&.snowpark:before { @include sprite-2x($ico-gnb-ico-snowpark); }
		&.experience:before { @include sprite-2x($ico-gnb-ico-experience); }
		&.party:before { @include sprite-2x($ico-gnb-ico-party); }
		&.party2:before { @include sprite-2x($ico-gnb-ico-party2); }
		&.architecture:before { @include sprite-2x($ico-gnb-ico-architecture); }
		&.package:before { @include sprite-2x($ico-gnb-ico-package); }
		&.event:before { @include sprite-2x($ico-gnb-ico-event); }
		&.story:before { @include sprite-2x($ico-gnb-ico-story); }
		&.sellinfo:before { @include sprite-2x($ico-gnb-ico-sellinfo); }
		&.more:before { @include sprite-2x($ico-gnb-ico-more); }
	}

	.sm {
		display: block;
		height: 33px;
		line-height: 33px;
		padding: 0 15px;
	}

	.site-head {
		padding: 0 2px 5px;
		font-weight: 500;
		font-size: 14px;
		letter-spacing: -.025em;

		span {
			font-weight: 400;
			font-size: 10px;
			letter-spacing: 0;
		}

		&.pyeongchang {
			color: #01609a;
			border-bottom: 2px solid #01609a;
		}
		&.jeju {
			margin-top: 20px;
			color: #f06e1a;
			border-bottom: 2px solid #f06e1a;
		}
	}

	.menu-wrap {
		border-bottom: 2px solid #f2f2f2;

		.row {
			display: flex;
			flex-wrap: wrap;
			box-sizing: border-box;
			padding: 12px 4px;

			&:not(:first-child) { border-top: 2px solid #f2f2f2; }
		}

		.col {
			box-sizing: border-box;
			width: 50%;
			margin: -12px 0;
			padding: 12px 0;
			&:not(:first-child) { padding-left: 10px; border-left: 2px solid #f2f2f2; }
		}

		.title {
			width: 100%;
			font-weight: 500;
			font-size: 14px;
			color: #232323;
			letter-spacing: -.025em;
		}

		.list {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			width: 100%;
			padding-top: 5px;

			a {
				display: block;
				line-height: 1.2;
				font-size: 12px;
				text-align: center;
				text-decoration: none;
				letter-spacing: -.05em;

				&:before {
					content: '';
					display: block;
					width: 40px;
					height: 40px;
					margin: 0 auto;
					@include sprite-2x($ico-reserve-1-1-1);
				}
			}

			.ico1-1-1:before { @include sprite-position-2x($ico-reserve-1-1-1); }
			.ico1-1-2:before { @include sprite-position-2x($ico-reserve-1-1-2); }
			.ico1-1-3:before { @include sprite-position-2x($ico-reserve-1-1-3); }
			.ico1-1-4:before { @include sprite-position-2x($ico-reserve-1-1-4); }
			.ico1-1-5:before { @include sprite-position-2x($ico-reserve-1-1-5); }

			.ico1-2-1:before { @include sprite-position-2x($ico-reserve-1-2-1); }
			.ico1-2-2:before { @include sprite-position-2x($ico-reserve-1-2-2); }

			.ico1-3-1:before { @include sprite-position-2x($ico-reserve-1-3-1); }
			.ico1-3-2:before { @include sprite-position-2x($ico-reserve-1-3-2); }
			.ico1-3-3:before { @include sprite-position-2x($ico-reserve-1-3-3); }
			.ico1-3-4:before { @include sprite-position-2x($ico-reserve-1-3-4); }
			.ico1-3-5:before { @include sprite-position-2x($ico-reserve-1-3-5); }

			.ico1-4-1:before { @include sprite-position-2x($ico-reserve-1-4-1); }
			.ico1-4-2:before { @include sprite-position-2x($ico-reserve-1-4-2); }

			.ico1-5-1:before { @include sprite-position-2x($ico-reserve-1-5-1); }
			.ico1-5-2:before { @include sprite-position-2x($ico-reserve-1-5-2); }

			.ico2-1-1:before { @include sprite-position-2x($ico-reserve-2-1-1); }
			.ico2-1-2:before { @include sprite-position-2x($ico-reserve-2-1-2); }
			.ico2-1-3:before { @include sprite-position-2x($ico-reserve-2-1-3); }
			.ico2-1-4:before { @include sprite-position-2x($ico-reserve-2-1-4); }
			.ico2-1-5:before { @include sprite-position-2x($ico-reserve-2-1-5); }
		}
	}

	.btn-area {
		margin-top: 20px;
		text-align: center;
	}

	.btn-cscenter {
		width: 135px;
	}

	.foot {
		flex-shrink: 0;
		padding: 0 25px 15px;
		padding: 0 safe(left,25px) 15px;
		font-size: 11px;
		background: #eaebed;
		position: relative;

		.info {
			font-style: normal;
			// font-weight: 300;
			font-size: 11px;
			color: #666;
		}

		.row { display: block; }

		.cell {
			position: relative;

			&:not(:first-child) {
				margin-left: 13px;

				&:after {
					content: '';
					display: block;
					width: 1px;
					height: 10px;
					background: #d0d0d0;
					position: absolute;
					top: 4px;
					left: -8px;
				}
			}
		}

		.copy {
			margin-top: 7px;
			font-size: 9px;
		}
	}

	.select-lang {
		margin: 0 -25px 15px;
		margin: 0 safe(left,-25px) 15px;

		&.is-open {
			.current {
				&:after {
					@include sprite-2x($ico-arw-lang-on);
				}
			}
		}

		.current {
			display: block;
			height: 35px;
			line-height: 35px;
			padding-left: 25px;
			font-weight: 500;
			font-size: 12px;
			color: #232323;
			text-decoration: none;
			border-bottom: 1px solid #fff;
			position: relative;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: calc(50% - 2px);
				right: 15px;

				@include sprite-2x($ico-arw-lang-off);
			}
		}

		.lang-list {
			display: none;
			background: #fff;
			position: relative;
			top: -1px;

			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 11px;
				right: 25px;
			}

			li {
				&:not(:first-child) {
					border-top: 1px solid #eaebed;
				}
			}

			a {
				display: block;
				height: 34px;
				line-height: 34px;
				padding-left: 25px;
				font-weight: 400;
				font-size: 12px;
				color: #666;
				text-decoration: none;
			}
		}
	}
}