//
// 본문 상단 배너
// --------------------------------------------------

/* 본문 상단 배너 */
.contents-banner {
	width: 100%;
	margin-bottom: 30px;
	background-color: #f4f0e9;
	position: relative;
	overflow: hidden;

	.common-photo-slider {
		padding-top: 27.4%;

		.swiper-pagination-bullet {
			background: #999;
			&-active { background: #b0895c; }
		}
	}

	.link {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		width: 100%;
		height: 100%;
		padding-bottom: 17px;
		text-decoration: none;
		background: url('about:blank');

		.main {
			display: block;
			max-width: 100%;
			font-weight: 500;
			font-size: 4.7vw;
			color: #232323;

			@include ellipsis;
		}

		.sub {
			display: block;
			max-width: 100%;
			font-size: 3.5vw;
			color: #232323;

			@include ellipsis;
		}
	}
	
}