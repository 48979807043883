//
// Heading
// --------------------------------------------------

/* 서브 타이틀 */
.head-sub {
	margin-bottom: 10px;
	padding-left: 5px;
	line-height: 1.2;
	font-weight: 500;
	font-size: 16px;
	color: #232323;
	letter-spacing: -.05em;

	&-info {
		margin-top: -31px;
		margin-bottom: 10px;
		padding-right: 5px;
		text-align: right;
		font-weight: 300;
		font-size: 14px;
	}

	&-btn {
		margin-top: -35px;
		margin-bottom: 10px;
		text-align: right;
		font-weight: 300;
		font-size: 14px;

		.btn + .btn { margin-left: 5px; }
	}
}

/* 서브 타이틀2 */
.head-sub2 {
	margin-bottom: 10px;
	padding-left: 5px;
	line-height: 1.2;
	font-weight: 500;
	font-size: 14px;
	color: #232323;
	letter-spacing: -.05em;
}

/* 서브 타이틀2 */
.head-sub3 {
	margin-bottom: 7px;
	padding-left: 5px;
	font-weight: 500;
	font-size: 13px;
	color: #232323;
}

/* 서브 타이틀 - 팝업 */
.head-sub-pop {
	margin-bottom: 10px;
	line-height: 1.2;
	font-weight: 500;
	font-size: 16px;
	color: #232323;
	letter-spacing: -.05em;
}