//
// 상세내용 컨테이너
// --------------------------------------------------

/* 상세내용 컨테이너 */
.detail-wrap {
	padding: 25px 0 30px;

	.tab-page + &,
	.tab-page-scroll + & {
		padding-top: 5px;
	}

	.common-content &:first-child {
		padding-top: 5px;
	}
	
	.head-sub .sub { font-size: 12px; }
	.head-sub:not(:first-child) { margin-top: 30px; }
	.head-sub2:not(:first-child) { margin-top: 20px; }

	.foot-desc {
		font-size: 12px;
	}

	.detail-txt {
		border-top: 1px solid #ddd;
		padding: 10px 5px 0;

		> p, > ul, > ol, > dl {
			&:not(:first-child) { margin-top: 5px; }
		}
	}
}