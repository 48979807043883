//
// 메인 이미지(비율사이즈)
// --------------------------------------------------

/* 메인 이미지(비율사이즈) */
.main-img {
	padding-top: 120%;
	background-size: cover;
	position: relative;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 25vw;
		background: linear-gradient(to bottom,rgba(0,0,0,0) 0%,rgba(0,0,0,.4) 100%);
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.con {
		box-sizing: border-box;
		width: 100%;
		padding: 0 6vw 6vw;
		padding: 0 safe(left,6vw) 6vw;
		color: #fff;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
	}

	.name {
		display: block;
		line-height: 1.2;
		font-weight: 500;
		font-size: 24px;

		@include mob-min {
			font-size: between(24px,56px,$mob-s-width,$tablet-s-width);
		}

		@include mob-max {
			font-size: 56px;
		}
	}

	.desc {
		display: block;
		padding-top: 7vw;
		font-size: 11px;
		letter-spacing: -.025em;
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 25px;
			height: 1px;
			background: #fff;
			position: absolute;
			top: 3.5vw;
			left: 0;
		}

		@include mob-min {
			font-size: between(11px,26px,$mob-s-width,$tablet-s-width);
		}

		@include mob-max {
			font-size: 26px;
		}
	}

	.btn-vr-view {
		display: block;
		height: 25px;
		line-height: 25px;
		font-size: 10px;
		color: #fff;
		position: absolute;
		top: 15px;
		right: 15px;
		z-index: 1;

		&:before {
			content: '';
			display: inline-block;
			margin-right: 5px;
			vertical-align: top;

			@include sprite-2x($ico-vr);
		}
	}
}