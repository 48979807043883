//
// 공통 텍스트 스타일
// --------------------------------------------------

/* 가격 텍스트 표기 */
.txt-price {
	font-weight: 500;
	color: #232323;

	.num { color: #d14e40; }
}

/* 첨부파일 */
.attach-file {
	padding: 5px 0;
	.filename { margin-right: 5px; }
}

/* 필수항목 * 표시 */
span.required {
	display: inline;
	font-size: 0;

	&:after {
		content: '*';
		display: inline;
		line-height: 1;
		font-weight: 500;
		font-size: 13px;
		color: $point;
		position: relative;
		top: 3px;
	}
}

/* 유효성 상태 텍스트 */
.state-msg {
	display: none;
	position: relative;

	&.is-valid {
		display: block;
		padding-left: 0;
		margin-top: 5px;
		font-weight: 300;
		font-size: 12px;
		color: #b0895c;

		&:before {
			content: '';
			display: inline-block;
			margin-right: 6px;

			@include sprite-2x($ico-state-valid);
		}
	}

	&.is-invalid {
		display: block;
		padding-left: 0;
		margin-top: 5px;
		font-weight: 300;
		font-size: 12px;
		color: #b0895c;

		&:before {
			content: '';
			display: inline-block;
			margin-right: 9px;
			position: relative;
			top: 1px;
			left: 1px;

			@include sprite-2x($ico-state-invalid);
		}
	}
}
span.state-msg {
	&.is-valid {
		display: inline-block;
		margin-top: 0;
		margin-left: 5px;
		padding-left: 0;
	}
	&.is-invalid {
		display: inline-block;
		margin-top: 0;
		margin-left: 5px;
		padding-left: 0;
	}
}

/* 약관동의 인풋 */
.agree-input {
	padding: 10px 5px;
	position: relative;

	label {
		font-weight: 500;

		span { font-weight: 300; }
	}

	.guide-link {
		position: absolute;
		top: 10px;
		right: 5px;
	}
}

/* 가이드 링크 */
a.guide-link {
	box-sizing: border-box;
	display: inline-block;
	font-weight: 400;
	font-size: 12px;
	color: #232323;
	text-indent: 0;
	text-decoration: none;
	letter-spacing: -.05em;
	border-bottom: 1px solid #232323;

	&:after {
		content: '';
		display: inline-block;
		margin-left: 4px;
		position: relative;
		top: -1px;

		@include sprite-2x($ico-guide-link);
	}
}

/* 공통 텍스트 문단  */
.common-txt {
	margin-bottom: 15px;
	padding: 0 5px;

	p, ul, ol, dl { margin: 5px 0; }

	.head-sub + & { margin-top: -5px; }
	& + & { margin-top: 0; }

	&:last-child { margin-bottom: 0; }
}

/* 공통 하단 설명 */
.common-foot-desc {
	margin-top: 10px;
	font-size: 12px;
	
	p, ul, ol, dl {
		&:not(:first-child) { margin-top: 5px; }
	}
}

/* 블릿 리스트 */
.dot-bu-list {
	margin-top: 5px;
	margin-bottom: 5px;

	&:first-child { margin-top: 0; }
	&:last-child { margin-bottom: 0; }
	
	&.auto { display: inline-block; }

	li {
		box-sizing: border-box;
		// display: table;
		width: 100%;
		padding-left: 10px;
		position: relative;

		&:not(:first-child) { margin-top: 5px; }

		&:before {
			content: '';
			display: block;
			width: 2px;
			height: 2px;
			background: #666;
			position: absolute;
			top: .7em;
			left: 2px;
		}
	}

	.common-foot-desc & {
		font-weight: 300;
		font-size: 12px;
	}
}

/* 당구장 블릿 리스트 */
.ref-bu-list {
	margin-top: 5px;
	margin-bottom: 5px;
	padding-left: 16px;
	text-indent: -16px;

	&:first-child { margin-top: 0; }
	&:last-child { margin-bottom: 0; }
	
	&.auto { display: inline-block; }

	.common-foot-desc & {
		padding-left: 13px;
		text-indent: -13px;
		font-weight: 300;
		font-size: 12px;
	}

	.accordion-content & {
		padding-left: 15px;
		text-indent: -15px;
	}

	li {
		&:not(:first-child) { margin-top: 5px; }
	}
}