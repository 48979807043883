// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$waterpark-attention1-name: 'waterpark-attention1';
$waterpark-attention1-x: 80px;
$waterpark-attention1-y: 390px;
$waterpark-attention1-offset-x: -80px;
$waterpark-attention1-offset-y: -390px;
$waterpark-attention1-width: 70px;
$waterpark-attention1-height: 70px;
$waterpark-attention1-total-width: 550px;
$waterpark-attention1-total-height: 460px;
$waterpark-attention1-image: '@@img/sprite/waterpark.png';
$waterpark-attention1: (80px, 390px, -80px, -390px, 70px, 70px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-attention1', );
$waterpark-attention10-name: 'waterpark-attention10';
$waterpark-attention10-x: 480px;
$waterpark-attention10-y: 160px;
$waterpark-attention10-offset-x: -480px;
$waterpark-attention10-offset-y: -160px;
$waterpark-attention10-width: 70px;
$waterpark-attention10-height: 70px;
$waterpark-attention10-total-width: 550px;
$waterpark-attention10-total-height: 460px;
$waterpark-attention10-image: '@@img/sprite/waterpark.png';
$waterpark-attention10: (480px, 160px, -480px, -160px, 70px, 70px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-attention10', );
$waterpark-attention11-name: 'waterpark-attention11';
$waterpark-attention11-x: 160px;
$waterpark-attention11-y: 390px;
$waterpark-attention11-offset-x: -160px;
$waterpark-attention11-offset-y: -390px;
$waterpark-attention11-width: 70px;
$waterpark-attention11-height: 70px;
$waterpark-attention11-total-width: 550px;
$waterpark-attention11-total-height: 460px;
$waterpark-attention11-image: '@@img/sprite/waterpark.png';
$waterpark-attention11: (160px, 390px, -160px, -390px, 70px, 70px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-attention11', );
$waterpark-attention12-name: 'waterpark-attention12';
$waterpark-attention12-x: 240px;
$waterpark-attention12-y: 390px;
$waterpark-attention12-offset-x: -240px;
$waterpark-attention12-offset-y: -390px;
$waterpark-attention12-width: 70px;
$waterpark-attention12-height: 70px;
$waterpark-attention12-total-width: 550px;
$waterpark-attention12-total-height: 460px;
$waterpark-attention12-image: '@@img/sprite/waterpark.png';
$waterpark-attention12: (240px, 390px, -240px, -390px, 70px, 70px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-attention12', );
$waterpark-attention13-name: 'waterpark-attention13';
$waterpark-attention13-x: 320px;
$waterpark-attention13-y: 390px;
$waterpark-attention13-offset-x: -320px;
$waterpark-attention13-offset-y: -390px;
$waterpark-attention13-width: 70px;
$waterpark-attention13-height: 70px;
$waterpark-attention13-total-width: 550px;
$waterpark-attention13-total-height: 460px;
$waterpark-attention13-image: '@@img/sprite/waterpark.png';
$waterpark-attention13: (320px, 390px, -320px, -390px, 70px, 70px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-attention13', );
$waterpark-attention14-name: 'waterpark-attention14';
$waterpark-attention14-x: 400px;
$waterpark-attention14-y: 390px;
$waterpark-attention14-offset-x: -400px;
$waterpark-attention14-offset-y: -390px;
$waterpark-attention14-width: 70px;
$waterpark-attention14-height: 70px;
$waterpark-attention14-total-width: 550px;
$waterpark-attention14-total-height: 460px;
$waterpark-attention14-image: '@@img/sprite/waterpark.png';
$waterpark-attention14: (400px, 390px, -400px, -390px, 70px, 70px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-attention14', );
$waterpark-attention2-name: 'waterpark-attention2';
$waterpark-attention2-x: 390px;
$waterpark-attention2-y: 80px;
$waterpark-attention2-offset-x: -390px;
$waterpark-attention2-offset-y: -80px;
$waterpark-attention2-width: 74px;
$waterpark-attention2-height: 70px;
$waterpark-attention2-total-width: 550px;
$waterpark-attention2-total-height: 460px;
$waterpark-attention2-image: '@@img/sprite/waterpark.png';
$waterpark-attention2: (390px, 80px, -390px, -80px, 74px, 70px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-attention2', );
$waterpark-attention3-name: 'waterpark-attention3';
$waterpark-attention3-x: 390px;
$waterpark-attention3-y: 240px;
$waterpark-attention3-offset-x: -390px;
$waterpark-attention3-offset-y: -240px;
$waterpark-attention3-width: 74px;
$waterpark-attention3-height: 70px;
$waterpark-attention3-total-width: 550px;
$waterpark-attention3-total-height: 460px;
$waterpark-attention3-image: '@@img/sprite/waterpark.png';
$waterpark-attention3: (390px, 240px, -390px, -240px, 74px, 70px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-attention3', );
$waterpark-attention4-name: 'waterpark-attention4';
$waterpark-attention4-x: 390px;
$waterpark-attention4-y: 0px;
$waterpark-attention4-offset-x: -390px;
$waterpark-attention4-offset-y: 0px;
$waterpark-attention4-width: 80px;
$waterpark-attention4-height: 70px;
$waterpark-attention4-total-width: 550px;
$waterpark-attention4-total-height: 460px;
$waterpark-attention4-image: '@@img/sprite/waterpark.png';
$waterpark-attention4: (390px, 0px, -390px, 0px, 80px, 70px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-attention4', );
$waterpark-attention5-name: 'waterpark-attention5';
$waterpark-attention5-x: 260px;
$waterpark-attention5-y: 260px;
$waterpark-attention5-offset-x: -260px;
$waterpark-attention5-offset-y: -260px;
$waterpark-attention5-width: 80px;
$waterpark-attention5-height: 70px;
$waterpark-attention5-total-width: 550px;
$waterpark-attention5-total-height: 460px;
$waterpark-attention5-image: '@@img/sprite/waterpark.png';
$waterpark-attention5: (260px, 260px, -260px, -260px, 80px, 70px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-attention5', );
$waterpark-attention6-name: 'waterpark-attention6';
$waterpark-attention6-x: 390px;
$waterpark-attention6-y: 160px;
$waterpark-attention6-offset-x: -390px;
$waterpark-attention6-offset-y: -160px;
$waterpark-attention6-width: 74px;
$waterpark-attention6-height: 70px;
$waterpark-attention6-total-width: 550px;
$waterpark-attention6-total-height: 460px;
$waterpark-attention6-image: '@@img/sprite/waterpark.png';
$waterpark-attention6: (390px, 160px, -390px, -160px, 74px, 70px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-attention6', );
$waterpark-attention7-name: 'waterpark-attention7';
$waterpark-attention7-x: 480px;
$waterpark-attention7-y: 0px;
$waterpark-attention7-offset-x: -480px;
$waterpark-attention7-offset-y: 0px;
$waterpark-attention7-width: 70px;
$waterpark-attention7-height: 70px;
$waterpark-attention7-total-width: 550px;
$waterpark-attention7-total-height: 460px;
$waterpark-attention7-image: '@@img/sprite/waterpark.png';
$waterpark-attention7: (480px, 0px, -480px, 0px, 70px, 70px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-attention7', );
$waterpark-attention8-name: 'waterpark-attention8';
$waterpark-attention8-x: 480px;
$waterpark-attention8-y: 80px;
$waterpark-attention8-offset-x: -480px;
$waterpark-attention8-offset-y: -80px;
$waterpark-attention8-width: 70px;
$waterpark-attention8-height: 70px;
$waterpark-attention8-total-width: 550px;
$waterpark-attention8-total-height: 460px;
$waterpark-attention8-image: '@@img/sprite/waterpark.png';
$waterpark-attention8: (480px, 80px, -480px, -80px, 70px, 70px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-attention8', );
$waterpark-attention9-name: 'waterpark-attention9';
$waterpark-attention9-x: 0px;
$waterpark-attention9-y: 390px;
$waterpark-attention9-offset-x: 0px;
$waterpark-attention9-offset-y: -390px;
$waterpark-attention9-width: 70px;
$waterpark-attention9-height: 70px;
$waterpark-attention9-total-width: 550px;
$waterpark-attention9-total-height: 460px;
$waterpark-attention9-image: '@@img/sprite/waterpark.png';
$waterpark-attention9: (0px, 390px, 0px, -390px, 70px, 70px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-attention9', );
$waterpark-bad-name: 'waterpark-bad';
$waterpark-bad-x: 480px;
$waterpark-bad-y: 302px;
$waterpark-bad-offset-x: -480px;
$waterpark-bad-offset-y: -302px;
$waterpark-bad-width: 68px;
$waterpark-bad-height: 52px;
$waterpark-bad-total-width: 550px;
$waterpark-bad-total-height: 460px;
$waterpark-bad-image: '@@img/sprite/waterpark.png';
$waterpark-bad: (480px, 302px, -480px, -302px, 68px, 52px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-bad', );
$waterpark-good-name: 'waterpark-good';
$waterpark-good-x: 480px;
$waterpark-good-y: 240px;
$waterpark-good-offset-x: -480px;
$waterpark-good-offset-y: -240px;
$waterpark-good-width: 68px;
$waterpark-good-height: 52px;
$waterpark-good-total-width: 550px;
$waterpark-good-total-height: 460px;
$waterpark-good-image: '@@img/sprite/waterpark.png';
$waterpark-good: (480px, 240px, -480px, -240px, 68px, 52px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-good', );
$waterpark-guide-room1-name: 'waterpark-guide-room1';
$waterpark-guide-room1-x: 130px;
$waterpark-guide-room1-y: 260px;
$waterpark-guide-room1-offset-x: -130px;
$waterpark-guide-room1-offset-y: -260px;
$waterpark-guide-room1-width: 120px;
$waterpark-guide-room1-height: 120px;
$waterpark-guide-room1-total-width: 550px;
$waterpark-guide-room1-total-height: 460px;
$waterpark-guide-room1-image: '@@img/sprite/waterpark.png';
$waterpark-guide-room1: (130px, 260px, -130px, -260px, 120px, 120px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-guide-room1', );
$waterpark-guide-room2-name: 'waterpark-guide-room2';
$waterpark-guide-room2-x: 260px;
$waterpark-guide-room2-y: 130px;
$waterpark-guide-room2-offset-x: -260px;
$waterpark-guide-room2-offset-y: -130px;
$waterpark-guide-room2-width: 120px;
$waterpark-guide-room2-height: 120px;
$waterpark-guide-room2-total-width: 550px;
$waterpark-guide-room2-total-height: 460px;
$waterpark-guide-room2-image: '@@img/sprite/waterpark.png';
$waterpark-guide-room2: (260px, 130px, -260px, -130px, 120px, 120px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-guide-room2', );
$waterpark-guide-room3-name: 'waterpark-guide-room3';
$waterpark-guide-room3-x: 260px;
$waterpark-guide-room3-y: 0px;
$waterpark-guide-room3-offset-x: -260px;
$waterpark-guide-room3-offset-y: 0px;
$waterpark-guide-room3-width: 120px;
$waterpark-guide-room3-height: 120px;
$waterpark-guide-room3-total-width: 550px;
$waterpark-guide-room3-total-height: 460px;
$waterpark-guide-room3-image: '@@img/sprite/waterpark.png';
$waterpark-guide-room3: (260px, 0px, -260px, 0px, 120px, 120px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-guide-room3', );
$waterpark-guide-room4-name: 'waterpark-guide-room4';
$waterpark-guide-room4-x: 130px;
$waterpark-guide-room4-y: 130px;
$waterpark-guide-room4-offset-x: -130px;
$waterpark-guide-room4-offset-y: -130px;
$waterpark-guide-room4-width: 120px;
$waterpark-guide-room4-height: 120px;
$waterpark-guide-room4-total-width: 550px;
$waterpark-guide-room4-total-height: 460px;
$waterpark-guide-room4-image: '@@img/sprite/waterpark.png';
$waterpark-guide-room4: (130px, 130px, -130px, -130px, 120px, 120px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-guide-room4', );
$waterpark-guide-season1-name: 'waterpark-guide-season1';
$waterpark-guide-season1-x: 0px;
$waterpark-guide-season1-y: 130px;
$waterpark-guide-season1-offset-x: 0px;
$waterpark-guide-season1-offset-y: -130px;
$waterpark-guide-season1-width: 120px;
$waterpark-guide-season1-height: 120px;
$waterpark-guide-season1-total-width: 550px;
$waterpark-guide-season1-total-height: 460px;
$waterpark-guide-season1-image: '@@img/sprite/waterpark.png';
$waterpark-guide-season1: (0px, 130px, 0px, -130px, 120px, 120px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-guide-season1', );
$waterpark-guide-season2-name: 'waterpark-guide-season2';
$waterpark-guide-season2-x: 130px;
$waterpark-guide-season2-y: 0px;
$waterpark-guide-season2-offset-x: -130px;
$waterpark-guide-season2-offset-y: 0px;
$waterpark-guide-season2-width: 120px;
$waterpark-guide-season2-height: 120px;
$waterpark-guide-season2-total-width: 550px;
$waterpark-guide-season2-total-height: 460px;
$waterpark-guide-season2-image: '@@img/sprite/waterpark.png';
$waterpark-guide-season2: (130px, 0px, -130px, 0px, 120px, 120px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-guide-season2', );
$waterpark-guide-season3-name: 'waterpark-guide-season3';
$waterpark-guide-season3-x: 0px;
$waterpark-guide-season3-y: 0px;
$waterpark-guide-season3-offset-x: 0px;
$waterpark-guide-season3-offset-y: 0px;
$waterpark-guide-season3-width: 120px;
$waterpark-guide-season3-height: 120px;
$waterpark-guide-season3-total-width: 550px;
$waterpark-guide-season3-total-height: 460px;
$waterpark-guide-season3-image: '@@img/sprite/waterpark.png';
$waterpark-guide-season3: (0px, 0px, 0px, 0px, 120px, 120px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-guide-season3', );
$waterpark-guide-season4-name: 'waterpark-guide-season4';
$waterpark-guide-season4-x: 0px;
$waterpark-guide-season4-y: 260px;
$waterpark-guide-season4-offset-x: 0px;
$waterpark-guide-season4-offset-y: -260px;
$waterpark-guide-season4-width: 120px;
$waterpark-guide-season4-height: 120px;
$waterpark-guide-season4-total-width: 550px;
$waterpark-guide-season4-total-height: 460px;
$waterpark-guide-season4-image: '@@img/sprite/waterpark.png';
$waterpark-guide-season4: (0px, 260px, 0px, -260px, 120px, 120px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-guide-season4', );
$waterpark-step-list-arw-name: 'waterpark-step-list-arw';
$waterpark-step-list-arw-x: 480px;
$waterpark-step-list-arw-y: 400px;
$waterpark-step-list-arw-offset-x: -480px;
$waterpark-step-list-arw-offset-y: -400px;
$waterpark-step-list-arw-width: 36px;
$waterpark-step-list-arw-height: 20px;
$waterpark-step-list-arw-total-width: 550px;
$waterpark-step-list-arw-total-height: 460px;
$waterpark-step-list-arw-image: '@@img/sprite/waterpark.png';
$waterpark-step-list-arw: (480px, 400px, -480px, -400px, 36px, 20px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-step-list-arw', );
$waterpark-step-list-arw2-name: 'waterpark-step-list-arw2';
$waterpark-step-list-arw2-x: 480px;
$waterpark-step-list-arw2-y: 364px;
$waterpark-step-list-arw2-offset-x: -480px;
$waterpark-step-list-arw2-offset-y: -364px;
$waterpark-step-list-arw2-width: 44px;
$waterpark-step-list-arw2-height: 26px;
$waterpark-step-list-arw2-total-width: 550px;
$waterpark-step-list-arw2-total-height: 460px;
$waterpark-step-list-arw2-image: '@@img/sprite/waterpark.png';
$waterpark-step-list-arw2: (480px, 364px, -480px, -364px, 44px, 26px, 550px, 460px, '@@img/sprite/waterpark.png', 'waterpark-step-list-arw2', );
$waterpark-width: 550px;
$waterpark-height: 460px;
$waterpark-image: '@@img/sprite/waterpark.png';
$waterpark-sprites: ($waterpark-attention1, $waterpark-attention10, $waterpark-attention11, $waterpark-attention12, $waterpark-attention13, $waterpark-attention14, $waterpark-attention2, $waterpark-attention3, $waterpark-attention4, $waterpark-attention5, $waterpark-attention6, $waterpark-attention7, $waterpark-attention8, $waterpark-attention9, $waterpark-bad, $waterpark-good, $waterpark-guide-room1, $waterpark-guide-room2, $waterpark-guide-room3, $waterpark-guide-room4, $waterpark-guide-season1, $waterpark-guide-season2, $waterpark-guide-season3, $waterpark-guide-season4, $waterpark-step-list-arw, $waterpark-step-list-arw2, );
$waterpark: (550px, 460px, '@@img/sprite/waterpark.png', $waterpark-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
