//
// 썸네일-제목 목록
// --------------------------------------------------

/* 썸네일-제목 목록 */
.thumb-title-list {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.item {
		width: calc(50% - 5px);

		&:nth-child(2) ~ .item { margin-top: 10px; }
	}

	.link {
		display: block;
		text-decoration: none;
	}

	.thumb {
		display: block;
		padding-top: 53.6%;
		background-size: cover;
	}

	.title {
		display: block;
		height: 30px;
		line-height: 30px;
		padding: 0 25px 0 14px;
		font-size: 13px;
		color: #fff;
		background: #1989cb;
		position: relative;

		@include ellipsis;

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: calc(50% - 6px);
			right: 15px;
			
			@include sprite-2x($ico-thumb-title-arw);
		}
	}

}