//
// 상품 목록
// --------------------------------------------------

/* 상품 목록 - 썸네일형 */
.product-list {
	.no-item {
		padding: 50px 0;
		text-align: center;
		font-weight: 300;
		font-size: 16px;
		color: #232323;
	}

	.item {
		&:not(:first-child) { margin-top: 40px; }
	}

	.thumb {
		padding-top: 40%;
		position: relative;
		.img { @include absFull; }
		& ~ .title { margin-top: 13px; }
	}

	.title {
		margin-bottom: 10px;
		line-height: 1.2;
		font-weight: 500;
		font-size: 16px;
		color: #232323;
	}

	.con-wrap {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding: 0 4px;
		font-size: 12px;
	}

	.info-list {
		li {
			padding-left: 7px;
			line-height: 1.3;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 2px;
				height: 2px;
				background: #232323;
				position: absolute;
				top: .6em;
				left: 0;
			}

			&:not(:first-child) { margin-top: 4px; }
		}
	}

	.info2 {
		align-self: flex-end;
		text-align: right;
		line-height: 1.3;
	}

	.price {
		line-height: 1.1;
		font-weight: 500;
		font-size: 18px;
		color: #232323;

		.num { color: #d14e40; }
	}

	.btn-area {
		margin-top: 18px;
	}

}