//
// SASS MIXIN
// --------------------------------------------------
/* stylelint-disable */

// clearfix
@mixin clearfix {
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}

// ellipsis
@mixin ellipsis {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

// hidden
@mixin hidden {
	position: absolute;
	left: -9999px;
}

// placeholderColor
@mixin placeholderColor($color: #aaa) {
	&::-webkit-input-placeholder { color: $color; }
	&:-ms-input-placeholder { color: $color; }
	&::-moz-placeholder { color: $color; opacity: 1; }
	&:-moz-placeholder { color: $color; opacity: 1; }
}

// absolute full
@mixin absFull {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

// spriteIR - sprite 믹스인을 IR로 적용하도록 래핑하는 믹스인
@mixin spriteIR($sprite) {
	@include sprite($sprite);

	height: 0;
	max-height: nth($sprite, 6);
	padding-top: nth($sprite, 6);
	overflow: hidden;
}

// afterSpriteIR - :after에 IR적용
@mixin afterSpriteIR($sprite, $w, $h, $position:relative) {
	width: $w;
	height: 0;
	max-height: $h;
	padding-top: $h;
	position: $position;
	overflow: hidden;

	&:after {
		@include sprite($sprite);

		content: '';
		display: block;
		margin: (nth($sprite, 6) / -2) 0 0 (nth($sprite, 5) / -2);
		position: absolute;
		top: 50%;
		left: 50%;
	}
}

// sprite-2x
// gulp.spritesmith 모듈에서 생성된 스프라이트를 1/2 사이즈로 바궈서 출력해주는 믹스인
// _icon-sprite.scss에 의존
@mixin sprite-2x($sprite){
	@include sprite-width-2x($sprite);
	@include sprite-height-2x($sprite);
	@include sprite-image($sprite);
	@include sprite-size-2x($sprite);
	@include sprite-position-2x($sprite);
}
@mixin sprite-width-2x($sprite) {
	width: (nth($sprite, 5) / 2);
}
@mixin sprite-height-2x($sprite) {
	height: (nth($sprite, 6) / 2);
}
@mixin sprite-size-2x($sprite) {
	background-size: round(nth($sprite, 7) / 2) round(nth($sprite, 8) / 2);
}
@mixin sprite-position-2x($sprite) {
	$sprite-offset-x: round(nth($sprite, 3) / 2);
	$sprite-offset-y: round(nth($sprite, 4) / 2);
	background-position: $sprite-offset-x  $sprite-offset-y;
}

// spriteIR-2x - sprite 믹스인을 IR로 적용하도록 래핑하는 믹스인
@mixin spriteIR-2x($sprite) {
	@include sprite-2x($sprite);

	height: 0;
	max-height: (nth($sprite, 6) / 2);
	padding-top: (nth($sprite, 6) / 2);
	overflow: hidden;
}

// afterSpriteIR-2x - :after에 IR적용
@mixin afterSpriteIR-2x($sprite, $w, $h, $position:relative) {
	width: $w;
	height: 0;
	max-height: $h;
	padding-top: $h;
	position: $position;
	overflow: hidden;

	&:after {
		@include sprite-2x($sprite);

		content: '';
		display: block;
		margin: (nth($sprite, 6) / -4) 0 0 (nth($sprite, 5) / -4);
		position: absolute;
		top: 50%;
		left: 50%;
	}
}