//
// FUNCTION
// --------------------------------------------------

/**
 * Computes a CSS calc function that betweens a value from
 * A to B over viewport-width A to viewport-width B.
 * Requires a media query to cap the value at B.
 */
@function between($to, $from, $toWidth, $fromWidth) {
	$slope: ($to - $from) / ($toWidth - $fromWidth);
	$base: $from - $slope * $fromWidth;

	@return calc(#{$base} + #{100vw * $slope});
}

/**
 * safe-area-inset 처리
 * $dir - 방향(all, top, right, bottom, left, 생략가능)
 * $value - 더해질 값(생략가능)
 */
@function safe($dir: all, $value: 0) {
	@if ($dir == all) {
		@if ($value > 0) {
			@return calc(#{$value} + env(safe-area-inset-top)) calc(#{$value} + env(safe-area-inset-right)) calc(#{$value} + env(safe-area-inset-bottom)) calc(#{$value} + env(safe-area-inset-left));
		}
		@else if ($value < 0) {
			@return calc((#{-$value} + env(safe-area-inset-top)) * -1) calc((#{-$value} + env(safe-area-inset-right)) * -1) calc((#{-$value} + env(safe-area-inset-bottom)) * -1) calc((#{-$value} + env(safe-area-inset-left)) * -1);
		}
		@else {
			@return env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
		}
	}
	@else {
		@if ($value > 0) {
			@return calc(#{$value} + env(safe-area-inset-#{$dir}));
		}
		@else if ($value < 0) {
			@return calc((#{-$value} + env(safe-area-inset-#{$dir})) * -1);
		}
		@else {
			@return env(safe-area-inset-#{$dir});
		}
	}
}