/* 펼침 토글 */
.btn-expand-toggle {
	box-sizing: border-box;
	position: absolute;
	bottom: -17px;
	left: calc(50% - 17px);
	@include spriteIR-2x($ico-expand);

	&.is-active {
		@include spriteIR-2x($ico-fold);
		& + .folding-content { display: block; }
	}
}

/* 펼침 토글2 */
.btn-expand-toggle2 {
	box-sizing: border-box;
	display: block;
	width: 80px;
	height: 29px;
	line-height: 29px;
	font-size: 13px;
	color: #999;
	margin: 15px auto 0;
	border: 1px solid #d9d8d6;
	position: relative;

	&:after {
		content: '';
		display: inline-block;
		margin-left: 5px;
		@include spriteIR-2x($ico-expand2-expand);
	}

	&.is-active {
		&:after {
			@include spriteIR-2x($ico-expand2-fold);
		}
	}
}

/* 위치보기 */
.btn-map-pin {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	top: -2px;

	@include spriteIR-2x($ico-map-pin);
}