//
// 타이틀
// --------------------------------------------------

/* 공통 페이지 타이틀 */
.common-page-title {
	flex-shrink: 0;
	box-sizing: border-box;
	height: 40px;
	line-height: 39px;
	margin-bottom: $com-mt;
	text-align: center;
	font-size: 16px;
	color: #232323;
	letter-spacing: -.025em;
	background: #fff;
	border-bottom: 1px solid #999;
	position: relative;

	.btn-back {
		@include afterSpriteIR-2x($ico-back,50px,40px,absolute);
		top: 0;
		left: 0;
		left: safe(left);
	}
}