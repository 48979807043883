//
// 시스템 팝업 - alert, confirm
// --------------------------------------------------

/* 시스템 팝업 - alert, confirm */
.system-pop {
	box-sizing: border-box;
	display: inline-block;
	max-width: 600px;
	padding: 25px 30px;
	vertical-align: middle;
	text-align: left;
	font-size: 14px;
	white-space: normal;
	background: #fff;
	box-shadow: 2px 2px 5px rgba(0,0,0,.1);
	outline: none;
	position: relative;
	z-index: 2001;

	&-dim {
		box-sizing: border-box;
		display: block;
		width: 100% !important;
		height: 100% !important;
		padding: 10px;
		text-align: center;
		font-size: 0;
		white-space: nowrap;
		background: rgba(0,0,0,.4);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 2000;
		overflow: auto;
		-webkit-overflow-scrolling: touch;

		// 상대적으로 센터 정렬 위한 요소
		&:after {
			content: '';
			display: inline-block;
			width: 0;
			height: 100%;
			vertical-align: middle;
		}
	}

	/* 닫기 */
	.btn-pop-close {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;

		@include afterSpriteIR-2x($ico-system-pop-close, 35px, 35px, absolute);
	}

	/* 팝업 내용 영역 */
	.msg-area {
		text-align: center;
		line-height: 1.4;
		font-weight: 400;
		font-size: 16px;
		color: #666;
	}

	/* 팝업 하단 버튼 영역 */
	.btn-area {
		margin-top: 17px;
		text-align: center;

		.btn { padding: 0 30px; }
		.btn + .btn { margin-left: 5px; }
	}
}