//
// 데이터 목록
// --------------------------------------------------

/* 데이터 목록 */
.data-list {
	border-bottom: 1px solid #ddd;

	.no-item {
		padding: 50px 0;
		text-align: center;
		border-top: 1px solid #232323;
	}

	.item {
		border-top: 1px solid #232323;
	}

	.title,
	.title-link {
		display: block;
		padding: 8px 10px;
		font-size: 14px;
		color: #666;
		background: #f5f5f5;
		position: relative;
	}

	.title-link {
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translate(0,-50%);

			@include sprite-2x($ico-data-list-arw);
		}
	}

	.info {
		padding: 5px 10px;

		dl {
			display: flex;
			flex-wrap: wrap;
			color: #232323;
		}

		dt {
			display: block;
			width: 90px;
			font-weight: 300;
		}

		dd {
			flex-basis: calc(100% - 90px);
			display: block;
			font-weight: 500;
			word-wrap: break-word;
		}
	}

	.data {
		font-weight: 300;
	}
}