//
// 블록 탭메뉴
// --------------------------------------------------

/* 블록 탭메뉴 */
.tab-block {
	box-sizing: border-box;
	display: flex;
	margin-bottom: 10px;

	/* 마진 없이 사용 */
	&.no-margin { margin-bottom: 0; }

	a {
		box-sizing: border-box;
		display: block;
		min-width: 100%;
		height: 100%;
		padding: 5px 20px;
		text-align: center;
		line-height: 1.1;
		font-weight: 300;
		font-size: 12px;
		color: #666;
		white-space: nowrap;
		text-decoration: none;
		letter-spacing: -.05em;
		border: 1px solid #ddd;

		span {
			font-weight: 300;
		}
	}

	.tab {
		box-sizing: border-box;
		flex-grow: 1;
		flex-shrink: 1;

		&:not(:first-child) { padding-left: 4px; }

		&.selected a {
			font-weight: 500;
			color: #fff;
			background: #666;
			border-color: #666;
		}
	}
}

/* 스크롤 타입 */
.tab-block-scroll {
	margin-bottom: 10px;
	position: relative;
	overflow: hidden;

	.tab-block  { margin-bottom: 0; }

	/* 마진 없이 사용 */
	&.no-margin { margin-bottom: 0; }
}