//
// 공통 아코디언
// --------------------------------------------------

/* 공통 아코디언 */
.common-accordion {
	border-bottom: 1px solid #ddd;

	.common-content & {
		margin-left: -#{$com-ml};
		margin-left: safe(left,-$com-ml);
		margin-right: -#{$com-mr};
		margin-right: safe(right,-$com-mr);
	}

	.accordion-section {		
		&.is-active {
			.accordion-title {
				background: #f0f1f3;
				border-top: 1px solid #f0f1f3;

				> a {
					&:after { @include sprite-position-2x($ico-accordion-on); }
				}
			}

			.accordion-content {
				background: none;
			}
		}
	}

	.accordion-title {
		padding: 0 ($com-ml + 5px);
		padding: 0 safe(left,($com-ml + 5px));
		font-weight: 500;
		font-size: 16px;
		color: #232323;
		word-wrap: break-word;
		background: #fff;
		border-top: 1px solid #ddd;
		position: relative;
		z-index: 1;
		transition: all .2s;

		@supports (position: sticky) {
			position: sticky;
			top: 0;
			left: 0;
		}

		> a {
			box-sizing: border-box;
			display: block;
			padding: 14px 20px 14px 0;
			line-height: 1.3;
			text-decoration: none;
			position: relative;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: calc(50% - 3px);
				right: 0;

				@include sprite-2x($ico-accordion-off);
			}
		}

		span {
			font-weight: 400;
			font-size: 13px;
		}
	}

	.accordion-content {
		display: none;
		// padding: 20px calc(6.6% + 5px);
		// padding: 20px 6.6%;
		padding: 20px ($com-ml + 5px);
		padding: 20px safe(left,($com-ml + 5px));
		font-size: 13px;
		color: #666;
		background: #fff;

		.data-layout-table,
		.dot-bu-list,
		.ref-bu-list {
			margin-left: 5px;
			margin-right: 5px;
		}

		.head-sub2 {
			&:not(:first-child) { margin-top: 25px; }
			& + .head-sub3:not(:first-child) { margin-top: 0; }
		}

		.head-sub3 {
			&:not(:first-child) { margin-top: 15px; }
		}
	}

	.license-tit {
		margin-bottom: 5px;
		font-weight: 500;
		color: #232323;
		&:not(:first-child) { margin-top: 15px; }
	}
	
	.license-list {
		li {
			// padding-left: 12px;
			// text-indent: -12px;

			&:not(:first-child) { margin-top: 5px; }
		}

		ul { margin-top: 5px; }

		table {
			text-indent: 0;
			th,
			td {
				font-size: 10px;
			}
		}
	}

	.license-txt ~ .license-txt,
	.license-txt ~ .license-list { margin-top: 5px; }
}