//
// spinner
// --------------------------------------------------

/* spinner */
.ui-spinner {
	box-sizing: border-box;
	display: inline-block;
	line-height: 0;
	vertical-align: middle;
	position: relative;

	input.txt {
		width: 90px;
		padding: 0 30px;
		text-align: center;
		border: 1px solid #ddd;

		&:active, &:focus { border-color: #ddd; }
	}

	.ui-spinner-button {
		box-sizing: border-box;
		display: block;
		width: 30px;
		height: 100%;
		text-decoration: none;
		position: absolute;
		border: 1px solid #ddd;
		cursor: pointer;
	}

	.ui-spinner-up {
		top: 0;
		right: 0;

		.ui-button-icon {
			display: block;
			position: relative;
			top: 50%;
			left: 50%;
			margin: -5px 0 0 -5px;
			@include sprite-2x($ico-spinner-plus);
		}
	}

	.ui-spinner-down {
		top: 0;
		left: 0;

		.ui-button-icon {
			display: block;
			position: relative;
			top: 50%;
			left: 50%;
			margin: 0 0 0 -5px;
			@include sprite-2x($ico-spinner-minus);
		}
	}
}