//
// 레이아웃 스타일
// --------------------------------------------------

/* 스킵 내비게이션 */
.skip-navi {
	@include hidden;

	&:focus {
		display: block;
		width: 100%;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 16px;
		color: #fff;
		text-decoration: none;
		background: $main-color;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99999;
	}
}

/* 레이아웃 컨테이너 */
.wrapper {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	min-width: $site-min-width;
	min-height: 100%;
	// padding: 55px 0 0;
}

/* 본문 */
.content-main {
	// flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	min-width: $site-min-width;
	// min-height: 100%;
	letter-spacing: -.05em;
}

/* 본문 내용 */
.common-content {
	margin: 0 $com-ml;
	margin-left: safe(left,$com-ml);
	margin-right: safe(right,$com-ml);
}