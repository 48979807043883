//
// 페이지 탭메뉴
// --------------------------------------------------

/* 페이지 탭메뉴 */
.tab-page {
	display: flex;
	margin-bottom: 20px;
	padding: 0 safe(left);
	box-shadow: inset 0 -3px 0 #ddd;
	overflow: hidden;

	/* 마진 없이 사용 */
	&.no-margin { margin-bottom: 0; }

	.common-content & {
		margin-left: -$com-ml;
		margin-left: safe(left,-$com-ml);
		margin-right: -$com-mr;
		margin-right: safe(left,-$com-mr);

		&:first-child { margin-top: -$com-mt; }
	}

	a {
		box-sizing: border-box;
		display: block;
		height: 40px;
		line-height: 37px;
		text-align: center;
		font-weight: 500;
		font-size: 14px;
		color: #999;
		white-space: nowrap;
		text-decoration: none;
		letter-spacing: -.05em;
		border-bottom: 3px solid #ddd;
	}

	.tab {
		box-sizing: border-box;
		display: block;
		flex: 1;

		&.selected a {
			color: #b0895c;
			border-color: #b0895c;
		}
	}

	/* 포인트 색상 타입 */
	&.type1 .tab.selected a { color: #006097; border-color: #006097; }
	&.type2 .tab.selected a { color: #d8671a; border-color: #d8671a; }
	
}

/* 스크롤 타입 */
.tab-page-scroll {
	margin-bottom: 20px;
	padding: 0 safe(left);
	box-shadow: inset 0 -3px 0 #ddd;
	position: relative;
	overflow: hidden;

	/* 마진 없이 사용 */
	&.no-margin { margin-bottom: 0; }

	.common-content & {
		margin-left: -$com-ml;
		margin-left: safe(left,-$com-ml);
		margin-right: -$com-mr;
		margin-right: safe(left,-$com-mr);

		&:first-child { margin-top: -$com-mt; }
		.tab-page { margin-top: 0; }
	}

	.tab-page {
		display: table;
		width: 100%;
		margin: 0;
		padding: 0;
		position: relative;
		overflow: visible;
	}

	.tab {
		display: table-cell;
		min-width: 30%;
	}

	a {
		min-width: 100%;
		padding: 0 15px;
	}
}