//
// 공통 기본정보2
// --------------------------------------------------

/* 공통 기본정보2 */
.common-basic-info2 {
	margin: 0 15px 30px;
	margin: 0 safe(left,15px) 30px;
	padding: 25px 4% 25px;
	font-size: 13px;
	color: #232323;
	border: 1px solid #d9d8d6;
	position: relative;

	&-title {
		margin-top: 35px;
		margin-bottom: 9px;
		text-align: center;
		font-weight: 500;
		font-size: 18px;
		color: #232323;
	}

	.basic-info-wrap {
		box-sizing: border-box;
		padding: 0 5px;
		text-align: center;
		border: #d9d8d6;
	}

	.btn-map-pin {
		margin-left: 2px;
	}

	tbody.folding {
		display: none;

		td {
			padding-top: 35px;
			background: url('@@img/com/bg-slash.png') no-repeat 50% 15px;
			background-size: 9px 9px;

			&:before {
				top: calc(35px + .7em);
			}
		}

		.dot-bu-list li:not(:first-child) { margin-top: 6px; }
	}

	.folding:not(tbody) {
		display: none;
		padding-top: 35px;
		text-align: left;
		background: url('@@img/com/bg-slash.png') no-repeat 50% 15px;
		background-size: 9px 9px;

		.title {
			margin-bottom: 10px;
			padding-bottom: 10px;
			text-align: center;
			font-weight: 500;
			font-size: 15px;
			color: #232323;
			border-bottom: 1px solid #ddd;
		}
	}

	.btn-expand-toggle {
		display: block;
		margin: 20px auto -42px;
		position: static;
	}
}