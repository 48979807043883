//
// 테이블 스타일
// --------------------------------------------------

/* 기본 테이블 */
.table-container {
	$cell-height: 40px;
	$padding-tb: 5px;
	$padding-lr: 10px;

	border-top: 1px solid #e8e8e8;
	border-bottom: 1px solid #e8e8e8;
	
	.table {
		width: 100%;
		table-layout: fixed;
		line-height: 1.5;
		background-color: #fff;
		border: 0 none;
	}

	th,
	td {
		box-sizing: border-box;
		word-wrap: break-word;
		border: 0 none;
	}

	th:not(.td) {
		height: $cell-height;
		padding: $padding-tb ($padding-lr - 5) ($padding-tb - 1) $padding-lr;
		text-align: left;
		font-weight: 500;
		font-size: 14px;
		color: #666;
		word-wrap: break-word;
		background-color: #f8f8f8;
	}

	td,
	.td {
		box-sizing: border-box;
		height: $cell-height;
		padding: $padding-tb $padding-lr $padding-tb - 1;
		text-align: left;
		font-weight: 400;
		font-size: 14px;
		color: #232323;
	}

	thead tr:not(:first-child) th { border-top: 1px solid #e8e8e8; }
	tbody tr:not(:first-child) {
		th, td { border-top: 1px solid #e8e8e8; }
	}
	thead + tbody tr:first-child {
		th, td { border-top: 1px solid #e8e8e8; }
	}

	.no-item {
		padding: 50px 0;
		text-align: center;
		&:hover { background: transparent; }
	}

	/* 상단 데코 */
	&.deco { border-top: 1px solid #222; }

	/* 내부 보더 타입 */
	&.inner-bordered {
		th:not(:first-child),
		td:not(:first-child) {
			border-left: 1px solid #e8e8e8;
		}
		th:not(:last-child)[rowspan],
		td:not(:last-child)[rowspan] {
			border-right: 1px solid #e8e8e8;
		}
	}

	/* 전체 보더 타입 */
	&.bordered {
		border-left: 1px solid #e8e8e8;
		border-right: 1px solid #e8e8e8;

		th:not(:first-child),
		td:not(:first-child) {
			border-left: 1px solid #e8e8e8;
		}
		th[rowspan],
		td[rowspan] {
			border-right: 1px solid #e8e8e8;
		}
	}

	&.center,
	.center {
		padding-left: 0;
		padding-right: 0;
		text-align: center;

		th:not(.left):not(.right),
		td:not(.left):not(.right) {
			padding-left: 0;
			padding-right: 0;
			text-align: center;
		}
	}

	&.left,
	.left { text-align: left; }
	&.right,
	.right { text-align: right; }
	
	.head { text-align: center; }
	.head-desc {
		font-weight: 300;
	}

	img { max-width: 100%; }

	.row:not(:first-child) { margin-top: 5px; }

	.desc {
		margin-top: 5px;
		line-height: 1.3;
		font-weight: 300;
		font-size: 12px;
	}

	span.desc {
		margin-left: 5px;
	}

	.editor-area {
		height: 500px;
		padding: 0;
		vertical-align: top;
	}

	.txt-content {
		min-height: 200px;
	}

	/* 사이즈 */
	/* small */
	&.small {
		$cell-height: 30px;
		$padding-tb: 5px;
		$padding-lr: 5px;

		th {
			height: $cell-height;
			padding: $padding-tb $padding-lr;
			font-size: 12px;
		}

		td {
			height: $cell-height;
			padding: $padding-tb $padding-lr;
			font-size: 12px;
		}
	}
}