//
// 헤더 스타일
// --------------------------------------------------

/* 공통 헤더 */
.common-header {
	flex-shrink: 0;
	box-sizing: border-box;
	width: 100%;
	min-width: $content-width;
	height: 55px;
	background: #002d4d;
	// position: fixed;
	position: relative;
	z-index: 400;
	// transition: top .4s;

	// &.is-scroll-down { top: -55px; }

	.site-logo {
		width: 100%;
		position: absolute;
		top: 3px;
		left: 0;
		z-index: 1;

		a {
			display: block;
			height: 0;
			position: absolute;
			top: 10px;
			left: 50%;
			overflow: hidden;
		}
	}

	.btn-menu-open {
		@include afterSpriteIR-2x($ico-header-menu,50px,55px,absolute);
		top: 0;
		left: 0;
		left: safe(left);
	}

	.btn-reserve-menu-open {
		@include afterSpriteIR-2x($ico-reservation-open,50px,55px,absolute);
		top: 0;
		right: 0;
		right: safe(right);
	}

	.btn-site-switch {
		@include afterSpriteIR-2x($ico-site-switch,50px,55px,absolute);
		top: 0;
		right: 0;
		right: safe(right);

		&.is-open:after {
			@include sprite-position-2x($ico-site-switch-on);
		}

		&.is-open + .site-switch {
			height: 40px;
		}
	}

	.site-switch {
		display: block;
		width: 100%;
		height: 0;
		padding: 0 safe(left);
		background: #002d4d;
		position: absolute;
		top: 54px;
		left: 0;
		transition: all .15s;
		overflow: hidden;
		z-index: 1;

		.link {
			box-sizing: border-box;
			display: table;
			width: 100%;
			height: 39px;
			border-top: 1px solid rgba(255,255,255,.2);
		}

		a {
			display: table-cell;
			line-height: 39px;
			text-align: center;
			font-size: 13px;
			vertical-align: middle;
			color: rgba(255,255,255,.6);
			text-decoration: none;
			white-space: nowrap;
			position: relative;

			&.selected {
				font-weight: 500;
				color: #fff;

				&:after {
					content: '';
					display: block;
					height: 2px;
					background: #fff;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
				}
			}

			&:first-child { padding-left: 10px; }
			&:last-child { padding-right: 10px; }
		}
	}

	&.is-hnr {
		.site-logo {
			a {
				$w: 76px;
				$h: 30px;

				width: $w;
				max-height: $h;
				padding-top: $h;
				margin-left: -($w / 2);
				background: url('@@img/com/site-logo-hnr.png') no-repeat;
				background-size: contain;
			}
		}
	}

	&.is-pyeongchang {
		background: #01609a;

		.site-logo {
			a {
				$w: 76px;
				$h: 30px;

				width: $w;
				max-height: $h;
				padding-top: $h;
				margin-left: -($w / 2);
				background: url('@@img/com/site-logo-pyeongchang.png') no-repeat;
				background-size: contain;
			}
		}

		.site-switch {
			background: #01609a;
		}
	}

	&.is-jeju {
		background: #f06e1a;

		.site-logo {
			a {
				$w: 76px;
				$h: 30px;

				width: $w;
				max-height: $h;
				padding-top: $h;
				margin-left: -($w / 2);
				background: url('@@img/com/site-logo-jeju.png') no-repeat;
				background-size: contain;
			}
		}

		.site-switch {
			background: #f06e1a;
		}
	}

	&.is-main {
		background: rgba(0,0,0,.25);

		.site-switch {
			height: 40px;
			background: rgba(0,0,0,.25);
			top: 56px;

			.link { border-top: 0; }
		}
	}
}