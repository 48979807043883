//
// 체크박스, 라디오버튼
// --------------------------------------------------

/* 체크박스 스타일 */
@mixin check-common {
	&:before {
		content: '';
		box-sizing: border-box;
		display: block;
		width: 16px;
		height: 16px;
		background-color: #fff;
		border: 1px solid #a4a4a4;
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -8px; // webkit에서 홀수사이즈 요소에 transform 적용시 흐려지는 버그 회피
		// transform: translate(0,-50%);
		
		@include sprite-image($ico-check-round);
		@include sprite-size-2x($ico-check-round);
		@include sprite-position-2x($ico-check-round);
	}
}

@mixin check-style {
	@include check-common;
	&:before { border-radius: 8px; }
}

@mixin check-style-checked {
	&:before {
		background-color: #b0895c;
		border-color: #b0895c;
		@include sprite-image($ico-check-round-on);
		@include sprite-size-2x($ico-check-round-on);
		@include sprite-position-2x($ico-check-round-on);
	}
}

@mixin check-style-focus {
	&:before {
		border-color: #b0895c;
	}
}

@mixin disabled-check-checked {
	&:before {
		background-color: #ddd;
		border-color: #ddd;
		@include sprite-image($ico-check-round-disabled);
		@include sprite-size-2x($ico-check-round-disabled);
		@include sprite-position-2x($ico-check-round-disabled);
	}
}
@mixin disabled-check {
	&:before {
		background-color: #f5f5f5;
		border-color: #ddd;
		background-image: none;
	}
}

/* 체크박스 */
/* example: input.check + label */
/* example: label.check > input + span */
.check-round2 {
	display: inline-block;
	vertical-align: middle;

	&[type],
	input { @include hidden; }

	&[type] + label,
	input + span {
		padding-left: 20px;
		padding-right: 10px;
		position: relative;

		@include check-style;
	}

	&[type]:checked + label,
	input:checked + span { @include check-style-checked; }

	&[type]:hover + label,
	input:hover + span,
	&[type]:focus + label,
	input:focus + span { @include check-style-focus; }

	&[disabled]:checked:hover + label,
	input[disabled]:checked:hover + span,
	&[disabled]:checked + label,
	input[disabled]:checked + span { @include disabled-check-checked; }

	&[disabled] + label,
	input[disabled] + span,
	&[disabled]:hover + label,
	input[disabled]:hover + span,
	&[disabled]:focus + label,
	input[disabled]:focus + span { @include disabled-check; }
}

/* 체크박스만 사용 */
/* example: .check-only > input + span */
.check-round2-only {
	display: inline-block;
	width: 16px;
	height: 16px;
	vertical-align: middle;
	position: relative;

	input {
		width: 100%;
		height: 100%;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}
	span {
		@include check-style;

		&:before {
			position: static;
			display: block;
			transform: none;
			margin-top: 0;
		}
	}
	input:checked + span { @include check-style-checked; }
	input:hover + span,
	input:focus + span { @include check-style-focus; }
	input[disabled]:checked + span { @include disabled-check-checked; }
	input[disabled] + span { @include disabled-check; }
}