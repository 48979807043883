//
// list
// --------------------------------------------------

.box-link-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	
	.item {
		box-sizing: border-box;
		width: calc((100% - 10px) / 2);
		border: 1px solid #ccc;

		&:nth-child(2) ~ .item { margin-top: 10px; }

		&.full {
			width: 100%;
			&:nth-child(1) ~ .item { margin-top: 10px; }
		}

		a {
			position: relative;
			display: block;
			padding: 10px 20px 10px 10px;
			color: #232323;
			font-size: 13px;
			text-decoration: none;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: calc(50% - 5px);
				right: 10px;

				@include sprite-2x($ico-arw-link2);
			}
		}
	}
}