//
// 키워드
// --------------------------------------------------

/* 키워드 */
.keyword-info {
	display: flex;
	justify-content: space-between;
	margin: 35px 7%;

	.data {
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
		text-align: center;
		width: 67px;
		height: 67px;
		line-height: 1.3;
		font-size: 13px;
		color: #fff;
		background: #38749d;
		border-radius: 50%;
	}

	.type {
		font-weight: 500;
		font-size: 13px;
	}

	.slash {
		font-size: 10px;
		position: relative;
		top: -2px;
	}

	.num-people {
		font-size: 12px;
		.txt { margin-top: -2px; }
	}

	.primary { font-weight: 500; font-size: 13px; }
	.num { font-size: 21px; }
	.area { font-weight: 500; font-size: 16px; }
	.unit { font-weight: normal; font-size: 11px; }

	.btn-vr {
		flex-shrink: 0;
		box-sizing: border-box;
		display: block;
		width: 67px;
		height: 67px;
		text-align: center;
		font-weight: 500;
		font-size: 11px;
		color: #fff;
		background: #777f84;
		border-radius: 50%;
		position: relative;

		&:before {
			content: '';
			display: block;
			margin: 0 auto 2px;

			@include sprite-2x($ico-vr);
		}
	}
}