//
// 기간조회 form
// --------------------------------------------------

/* 기간조회 form */
.search-period-form {
	margin: 0 -#{$com-ml};
	margin: 0 safe(left,-$com-ml);
	margin-bottom: 20px;
	padding: 20px $com-ml;
	padding: 20px safe(left,$com-ml);
	background: #f5f5f5;
	text-align: center;

	&.first {
		margin-top: -$com-mt;
	}

	&.type2 {
		margin-left: 0;
		margin-right: 0;
		padding: 0;
		background: #fff;
	}

	.tab-page-scroll + & {
		margin-top: -30px;
	}

	.input-from-to {
		display: block;
		margin-bottom: 10px;
	}

	.btn-area {
		display: flex;

		&:not(:last-child) { margin-bottom: 5px; }

		.btn { 
			flex: 1;
			padding: 0;

			&:not(:first-child) { margin-left: 5px; }
		}
	}
}