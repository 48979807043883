/* 스크롤 지도 */
.full-map {
	margin: -20px -#{$com-ml} 0;
	margin: -20px safe(left,-$com-ml) 0;
	background: #333;
	position: relative;
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	.con {
		display: flex;
		min-width: 700px;
		height: 100%;
		justify-content: center;
		align-items: center;
		position: relative;

		img { width: 100%; }
	}
}