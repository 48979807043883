//
// 페이지 하단 버튼 영역
// --------------------------------------------------

/* 페이지 하단 버튼 영역 */
.page-foot {
	display: flex;
	justify-content: center;
	margin: 30px -#{$com-ml} 0;
	margin: 30px safe(left,-$com-ml) 0;
	padding: 0 $com-ml;
	padding: 0 safe(left,$com-ml);
	text-align: center;

	&.no-margin {
		margin-top: 0;
	}

	&.bordered {
		padding-top: 30px;
		border-top: 1px solid #ddd;
	}

	&.block {
		margin-top: 0;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.detail-wrap + &,
	.photo-detail-info + & {
		margin-top: 0;
	}

	.btn-action + .btn-action {
		margin-left: 5px;
	}

	.btn-action {
		flex-basis: 150px;
		min-width: auto;
		padding: 0 3%;
	}

	&.full {
		justify-content: space-between;

		.btn-action {
			flex-basis: auto;
			width: 100%;
			min-width: auto;
			padding: 0 10px;
		}
	}

	/* 더보기 버튼 */
	.btn-page-more {
		font-weight: 300;
		color: #232323;
	}

	/* 텍스트 내용 */
	.txt-con {
		width: 100%;
		text-align: left;
	}
}