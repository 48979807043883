//
// 폼 레이아웃
// --------------------------------------------------

/* 폼 레이아웃 */
.layout-form {
	$rowHeight: 35px;

	@include clearfix;

	.form-row {
		display: flex;
		&:not(:last-child) { padding-bottom: 15px; }
	}

	.form-head {
		flex-shrink: 0;
		box-sizing: border-box;
		display: block;
		width: 65px;
		padding: 9px 0 0;
		line-height: 1.2;
		font-weight: 500;
		font-size: 13px;
		color: #232323;
		letter-spacing: -.05em;

		&.multi { padding-top: 2px; }

		&.small {
			font-size: 12px;
			&.multi { padding-top: 3px; }
		}
	}

	.form-cell {
		box-sizing: border-box;
		flex: 1 1 auto;
		display: block;
		width: 100%;
		align-items: center;
	}

	.form-desc {
		margin-top: 5px;
		font-weight: 300;
		font-size: 12px;
		color: #666;
	}

	.form-desc + .form-desc { margin-top: 5px; }

	.txt-row {
		display: flex;
		&:not(:last-child) { padding-bottom: 15px; }

		.form-head {
			padding-top: 2px;
		}
	}
}