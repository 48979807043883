//
// 사진 갤러리
// --------------------------------------------------

/* 사진 갤러리 (사진 비율 padding-top으로 필요에 따라 재정의) */
.common-photo-slider {
	$this: &;

	padding-top: 80%;
	position: relative;
	overflow: hidden;

	@at-root {
		.common-content {
			#{$this}:not(.inner) {
				margin: 0 -#{$com-ml};
				margin: 0 safe(left,-$com-ml);
				&:first-child { margin-top: -#{$com-mt}; }
			}
			.tab-page-scroll + #{$this},
			.tab-page + #{$this} {
				margin-top: -20px;
			}
		}
	}

	// &:after {
	// 	content: '';
	// 	display: block;
	// 	width: 100%;
	// 	height: 50px;
	// 	background: linear-gradient(to bottom,rgba(0,0,0,0) 0%,rgba(0,0,0,.4) 100%);
	// 	position: absolute;
	// 	bottom: 0;
	// 	left: 0;
	// }

	.slider-list {
		display: flex;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.slider-item {
		display: flex;
		flex-shrink: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: 50% 50%;
	}

	.btn-prev {
		box-sizing: border-box;
		width: 36px;
		height: 36px;
		background: rgba(0,0,0,.5) url('@@img/com/slider-arw-prev.png') no-repeat 50% 50% / 35px 35px;
		position: absolute;
		bottom: 0;
		right: 36px;
		cursor: pointer;
	}

	.btn-next {
		box-sizing: border-box;
		width: 36px;
		height: 36px;
		background: rgba(0,0,0,.5) url('@@img/com/slider-arw-next.png') no-repeat 50% 50% / 35px 35px;
		position: absolute;
		bottom: 0;
		right: 0;
		cursor: pointer;

		&:after {
			content: '';
			display: block;
			width: 1px;
			background: rgba(255,255,255,.4);
			position: absolute;
			top: 11px;
			bottom: 11px;
			left: 0;
		}
	}

	.pagination {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		width: 100%;
		height: 0;
		position: absolute;
		bottom: 15px;
		left: 0;
		z-index: 1;
	}

	.swiper-pagination-bullet {
		display: block;
		width: 7px;
		height: 7px;
		background: #fff;
		border-radius: 50%;

		&:not(:first-child) { margin-left: 4px; }
		&-active { background: #31a0e0; }

		// 1개 일때는 숨김
		&:first-child:last-child { display: none; }

		@at-root {
			.site-jeju &-active { background: #f06e1a; }
		}
	}

	.btn-vr-view {
		display: block;
		height: 25px;
		line-height: 25px;
		font-size: 10px;
		color: #fff;
		position: absolute;
		bottom: 8px;
		right: 15px;
		z-index: 1;

		&:before {
			content: '';
			display: inline-block;
			margin-right: 5px;
			vertical-align: top;

			@include sprite-2x($ico-vr);
		}
	}

	.btn-video-view {
		display: block;
		height: 25px;
		line-height: 25px;
		font-size: 10px;
		color: #fff;
		position: absolute;
		bottom: 8px;
		left: 15px;
		z-index: 1;

		&:before {
			content: '';
			display: inline-block;
			margin-right: 5px;
			vertical-align: top;

			@include sprite-2x($ico-video);
		}
	}
}