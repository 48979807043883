//
// 푸터 스타일
// --------------------------------------------------

/* 공통 푸터 */
.common-footer {
	flex-grow: 1;
	flex-shrink: 0;
	box-sizing: border-box;
	width: 100%;
	min-height: 100%;
	min-width: $content-width;
	margin-top: $com-mb;
	padding: 20px 0 20px;
	text-align: center;
	background: #eaebed;
	overflow: hidden;

	// .foot-nav {
	// 	margin-bottom: 8px;
	// 	font-size: 0;

	// 	a {
	// 		font-weight: 500;
	// 		font-size: 12px;
	// 		color: #666;
	// 		position: relative;

	// 		&:not(:first-child) {
	// 			margin-left: 26px;
				
	// 			&:after {
	// 				content: '';
	// 				display: block;
	// 				width: 2px;
	// 				height: 2px;
	// 				background: #adadad;
	// 				position: absolute;
	// 				top: 8px;
	// 				left: -13px;
	// 			}
	// 		}
	// 	}

	// 	// @include mob-small {
	// 	// 	a:not(:first-child) {
	// 	// 		margin-left: 13px;
	// 	// 		&:after { left: -7px; }
	// 	// 	}
	// 	// }
	// }

	.info {
		display: block;
		font-style: normal;
		// font-weight: 300;
		font-size: 11px;
		color: #666;

		.row { display: block; }

		.cell {
			position: relative;

			&:not(:first-child) {
				margin-left: 13px;

				&:after {
					content: '';
					display: block;
					width: 1px;
					height: 10px;
					background: #d0d0d0;
					position: absolute;
					top: 4px;
					left: -8px;
				}
			}
		}
	}

	// .copy {
	// 	font-style: normal;
	// 	font-weight: 300;
	// 	font-size: 11px;
	// 	color: #999;
	// }
}