//
// 상단 헤더 배너
// --------------------------------------------------

/* 상단 헤더 배너 */
.header-banner {
	width: 100%;
	min-width: $content-width;
	background-color: #232323;
	position: relative;
	overflow: hidden;

	.common-photo-slider {
		padding-top: 29.33%;
	}

	.swiper-pagination-bullet {
		&-active { background: #b0895c; }
	}

	.btn-close {
		display: block;
		position: absolute;
		bottom: 0;
		right: 0;

		@include afterSpriteIR-2x($ico-header-banner-close,40px,40px,absolute);
	}

	.link {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		width: 100%;
		height: 100%;
		padding-bottom: 17px;
		text-decoration: none;
		background: url('about:blank');

		.main {
			display: block;
			max-width: 100%;
			font-weight: 500;
			font-size: 5vw;
			color: #fff;

			@include ellipsis;
		}

		.sub {
			display: block;
			max-width: 100%;
			font-size: 3.5vw;
			color: #fff;

			@include ellipsis;
		}
	}
	
}