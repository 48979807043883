//
// 하단 고정버튼 영역
// --------------------------------------------------

/* 하단 고정버튼 영역 */
.foot-sticky-action {
	display: flex;

	.wrapper & {
		width: 100%;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 300;
	}

	.common-content & {
		margin: $com-mb -#{$com-ml} -#{$com-mb};
		margin: $com-mb safe(left,-$com-ml) -#{$com-mb};

		&.no-margin { margin-top: 0; }
	}

	.common-accordion + &,
	.detail-wrap + & {
		margin-top: 0;
	}

	@supports (position: sticky) {
		.wrapper & {
			width: auto;
			position: sticky;
		}
	}

	> a {
		flex: 1;
		display: flex;
		height: 55px;
		justify-content: center;
		align-items: center;
		font-weight: 500;
		font-size: 16px;
		color: #fff;
		letter-spacing: -.05em;
		text-decoration: none;

		&:first-child { padding-left: safe(left); }
		&:last-child { padding-right: safe(right); }

		.multi {
			text-align: center;
			font-size: 13px;
		}

		small {
			font-size: 11px;
		}
	}

	.basic { background: #232323; }
	.light { background: #999; }
	.primary { background: #b0895c; }

	i {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;

		&.call { @include sprite-2x($ico-foot-call); }
		&.room { @include sprite-2x($ico-foot-room); }
		&.reservation { @include sprite-2x($ico-foot-reservation); }
		&.download { @include sprite-2x($ico-foot-download); }
		&.season { @include sprite-2x($ico-foot-season); }
		&.ticket { @include sprite-2x($ico-foot-ticket); }
		&.inquiry { @include sprite-2x($ico-foot-inquiry); }
		&.bus { @include sprite-2x($ico-foot-bus); }
	}
}