//
// 사진-상세 정보
// --------------------------------------------------

/* 사진-상세 정보 */
.photo-detail-info {
	.common-photo-slider {
		padding-top: 56.25%;
	}

	.photo-single {
		padding-top: 56.25%;
		background-size: cover;
	}

	.detail-wrap {
		padding-top: 15px;
	}

	.head-sub .sub {
		font-weight: 400;
		font-size: 12px;
	}

	.btn-map-pin {
		margin-left: 2px;
	}

	.folding {
		display: none;
	}

	.data-layout-table {
		tbody:not(:last-child) tr:last-child {
			th, td {
				padding-bottom: 6px;
			}
		}
	}
}