//
// 공통 기본정보
// --------------------------------------------------

/* 공통 기본정보 */
.common-basic-info {
	padding: 20px 8%;
	padding: 20px safe(left,8%);
	font-size: 13px;
	color: #fff;
	background: #666;
	position: relative;

	.common-content & {
		margin: 0 -#{$com-ml};
		margin: 0 safe(left,-$com-ml);

		&:first-child { margin-top: -20px; }
	}

	.tab-page + &,
	.tab-page-scroll + & { margin-top: -20px; }
	

	.title {
		margin-bottom: 9px;
		text-align: center;
		font-weight: 500;
		font-size: 18px;
		color: inherit;
	}

	.basic-info-wrap {
		box-sizing: border-box;
		padding: 15px 0 0;
		text-align: center;
		border-top: 1px solid rgba(215,215,215,.2);
	}

	.data-layout-table {
		th:before {
			background: #fff;
		}
	}

	.btn-map-pin {
		margin-left: 2px;
	}

	.dot-bu-list {
		text-align: left;
		li:before { background: #fff; }
	}

	.ref-bu-list {
		text-align: left;
	}
}