//
// 좌우 측면 사진 내용
// --------------------------------------------------

/* 좌우 측면 사진 내용 */
.side-photo {
	padding-top: 25px;
	color: #232323;

	.wrap {
		&:not(:first-child) { padding-top: 35px; }
		&:nth-child(even) {
			.img {
				margin-left: auto;
				margin-right: 8.5%;
			}
		}
	}

	.title {
		margin-bottom: 15px;
		text-align: center;
		font-weight: 500;
		font-size: 16px;

		.small {
			font-weight: 400;
			font-size: 13px;
		}
	}

	.img {
		margin-left: 8.5%;
		padding-top: 56%;
		background-size: cover;
	}
}