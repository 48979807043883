//
// 이미지 목록
// --------------------------------------------------

/* 이미지 목록 */
.thumb-img-list {
	border-bottom: 1px solid #ddd;

	.no-item {
		padding: 50px 0;
		text-align: center;
	}

	.item {
		&:not(:first-child) {
			margin-top: 10px;
		}
	}

	.link {
		display: block;
		text-decoration: none;
	}

	.thumb {
		padding-top: 42.86%;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;

		img { @include absFull; }
	}

	.info {
		padding: 15px 5px;
	}

	.title {
		line-height: 1.2;
		font-weight: 500;
		font-size: 16px;
		color: #232323;
	}

	.date {
		margin-top: 3px;
		font-weight: 300;
		font-size: 13px;
		color: #666;
	}
}