//
// 폼 레이아웃
// --------------------------------------------------

/* 폼 레이아웃 */
.layout-form2 {
	$rowHeight: 35px;

	@include clearfix;

	.form-row {
		display: block;

		&:not(:last-child) { padding-bottom: 10px; }
	}

	.form-head {
		box-sizing: border-box;
		display: block;
		padding: 6px 0 5px;
		font-weight: 500;
		font-size: 13px;
		color: #232323;
		letter-spacing: -.05em;
	}

	.form-cell {
		box-sizing: border-box;
		display: block;
	}

	.form-desc {
		margin-top: 5px;
		font-weight: 300;
		font-size: 12px;
		color: #666;
	}

	.form-desc + .form-desc { margin-top: 5px; }
}