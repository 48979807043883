//
// 페이지 버튼
// --------------------------------------------------

/* 페이지 버튼 */
.btn-action {
	$height: 40px;
	$padding-lr: 20px;

	box-sizing: border-box;
	display: inline-block;
	min-width: 120px;
	height: $height;
	line-height: $height - 2;
	padding: 0 $padding-lr;
	vertical-align: middle;
	text-align: center;
	font-family: 'Noto Sans KR', sans-serif;
	font-weight: 400;
	font-size: 14px;
	color: #fff;
	white-space: nowrap;
	background: #999;
	border: 1px solid #999;
	border-radius: 0;
	cursor: pointer;
	// transition: color .2s, background .2s, border .2s;

	// &:hover, &:focus { background-color: #888; }

	/* button 태그 클릭 시 텍스트 밀림 제거 */
	&:focus span,
	&:active span {
		position: relative;
		top: 0;
		left: 0;
	}

	// primary
	&.primary {
		color: #fff;
		background: #232323;
		border-color: #232323;
		
		// &:hover, &:focus { background-color: #000; }
	}

	// 라운드 타입
	&.round {
		border-radius: $height / 2;
	}

	// 보더타입
	&.light {
		color: #888;
		background: #fff;
		border-color: #999;
		
		// &:hover, &:focus { color: #fff; background-color: #999; }

		&.primary {
			color: #232323;
			border-color: #232323;

			// &:hover, &:focus { color: #fff; background-color: #000; }
		}
	}
}

a.btn-action { text-decoration: none !important; }