//
// 목록 상단 검색
// --------------------------------------------------

/* 목록 상단 검색 */
.list-search-form {
	margin-bottom: 20px;

	.form-wrap {
		display: flex;
	}

	.select {
		flex-shrink: 0;
		width: 100px;
		border-color: #232323;
		border-right: 0;
	}

	.keyword {
		flex: 1;
		width: 100%;
		border-color: #232323;
	}

	.btn-submit {
		flex-shrink: 0;
		box-sizing: border-box;
		display: block;
		background: #232323;

		@include afterSpriteIR-2x($ico-search,40px,35px,relative);
	}
}