//
// 미디어 쿼리 MIXIN
// --------------------------------------------------

// Break Point (Desktop First 기준 내림차순 설정)
$desktop-l-width	: 1200px;
$tablet-l-width		: 1024px;
$tablet-s-width		: 768px;
$mob-l-width		: 640px;
$mob-m-width		: 425px;
$mob-s-width		: 359px;

// only PC
@mixin pc-only {
	@media all and (min-width: $tablet-l-width + 1), print {
		@content;
	}
}
// PC large
@mixin pc-large {
	@media all and (min-width: $desktop-l-width + 1), print {
		@content;
	}
}
// PC small
@mixin pc-small {
	@media all and (max-width: $desktop-l-width), print {
		@content;
	}
}

// --------------------------------------------------

// 태블릿
@mixin tab {
	@media screen and (max-width: $tablet-l-width) {
		@content;
	}
}
// only 태블릿
@mixin tab-only {
	@media screen and (min-width: $tablet-s-width) and (max-width: $tablet-l-width) {
		@content;
	}
}
// 모바일
@mixin mob {
	@media screen and (max-width: $tablet-s-width - 1) {
		@content;
	}
}
// 모바일 large
@mixin mob-large {
	@media screen and (max-width: $mob-l-width) {
		@content;
	}
}
// 모바일 mid
@mixin mob-mid {
	@media screen and (max-width: $mob-m-width) {
		@content;
	}
}
// 모바일 small
@mixin mob-small {
	@media screen and (max-width: $mob-s-width) {
		@content;
	}
}

// --------------------------------------------------

// 모바일 min
@mixin mob-min {
	@media screen and (min-width: $mob-s-width) {
		@content;
	}
}
// 모바일 max
@mixin mob-max {
	@media screen and (min-width: $tablet-s-width) {
		@content;
	}
}