//
// jqueryui datepicker 커스텀 스타일
// --------------------------------------------------
/* stylelint-disable */

// 기본 달력 테마 디자인
$datepickerDefaultTheme: '.date-picker';

/* 날짜선택 아이콘 버튼 */
/*
#{$datepickerDefaultTheme} + .ui-datepicker-trigger {
	display: inline-block;
	width: 21px;
	height: 0;
	max-height: 21px;
	padding-top: 21px;
	margin-left: 2px;
	vertical-align: middle;
	overflow: hidden;

	@include sprite($ico-calendar);

	input:hover + &,
	input:focus + & {
		@include sprite($ico-calendar-on);
	}

	input[readonly] + & {
		@include sprite($ico-calendar-readonly);
		cursor: default;
	}
	input[disabled] + & {
		@include sprite($ico-calendar-disabled);
		cursor: default;
	}
}
*/

.ui-datepicker-inline {
	display: inline-block !important;
}

/* DatePicker Container */
#{$datepickerDefaultTheme}.ui-datepicker,
#{$datepickerDefaultTheme} .ui-datepicker
{
	display: none;
	box-sizing: border-box;
	width: 230px;
	padding: 0 15px 15px;
	height: auto;
	vertical-align: top;
	z-index: 200;
	background: #fff;
	border: 1px solid #ddd;
	overflow: hidden;

	a {
		text-decoration: none;
	}

	/* multiple */
	&.ui-datepicker-multi-2 {
		width: 560px !important;
	}

	.ui-datepicker-group {
		box-sizing: border-box;
		float: left;
		width: 48%;

		&:not(:first-child) {
			margin-left: 4%;
		}
	}

	.ui-datepicker-group-first .ui-datepicker-title {
		text-align: right;
	}
	.ui-datepicker-group-last .ui-datepicker-title {
		text-align: left;
		margin-left: -15px;
	}
	.ui-datepicker-group-last .ui-datepicker-title .ui-datepicker-year:before {
		content: '~ '
	}

	/* DatePicker Table */
	table {
		width: 100%;
	}
	.ui-datepicker-header {
		background: #fff;
		color: #e0e0e0;
		font-weight: 500;
		line-height: 39px;
		position: relative;
	}
	.ui-datepicker-title {
		box-sizing: border-box;
		height: 47px;
		padding-top: 5px;
		text-align: center;
		color: #333;
		font-size: 0;
		
		/* 년월 선택 select */
		select {
			width: 70px;
			height: 23px;
			line-height: 23px;
			font-size: 14px !important;
			position: relative;
			top: 10px;

			&:not(:first-child) {
				width: 50px;
				margin-left: 5px;
			}
		}

		.ui-datepicker-year {
			font-size: 18px;
			vertical-align: top;

			&:after {
				content: '.';
				display: inline;
			}
		}

		.ui-datepicker-month {
			vertical-align: top;
			font-size: 18px;
		}
	}

	.ui-datepicker-prev, .ui-datepicker-next {
		display: inline-block;
		width: 20px;
		max-height: 20px;
		padding-top: 20px;
		font-size: 0;
		color: #fff;
		cursor: pointer;
		position: absolute;
		overflow: hidden;
	}
	.ui-datepicker-prev {
		top: 10px;
		left: 0;

		@include afterSpriteIR-2x($ico-datepicker-prev, 31px, 31px, absolute);
	}
	.ui-datepicker-next {
		top: 10px;
		right: 0;

		@include afterSpriteIR-2x($ico-datepicker-next, 31px, 31px, absolute);
	}
	thead {
		background-color: none;
	}
	th {
		text-transform: uppercase;
		font-size: 12px;
		padding: 0 0 5px;
		font-weight: 400;
		color: #666;
	}
	tbody td {
		width: 14.28%;
		border: 1px solid #ddd;
		font-size: 12px;
	}
	td span, td a {
		display: block;
		text-align: center;
		height: 25px;
		line-height: 25px;
	}
	.ui-datepicker-today:not(.ui-datepicker-current-day) {
		background: #bbb;
		color: #fff;
	}
	td:hover:not(.selected) {
		color: #333;
		background: #d6e9f7;
	}
	.ui-datepicker-current-day {
		color: #fff;
		background: #006097;
	}

	// 시작날짜
	.selected-start:not(.selected-end) {
		color: #fff !important;
		background: #006097;
	}
	// 종료날짜
	.selected-end:not(.selected-start) {
		color: #fff !important;
		background: #3890c2;
	}
	// 시작-종료 사이 선택된 날짜
	.selected:not(.selected-start):not(.selected-end) {
		background: #d6e9f7;
	}
	
	// 선택불가
	.ui-datepicker-unselectable {
		background: #f5f5f5;
		color: #bbb;
	}

	// 다른 달
	.ui-datepicker-unselectable.ui-datepicker-other-month {
		background: #fff;
	}
	
	// 일요일
	.ui-datepicker-week-end:first-child:not(.selected-start):not(.selected-end) {
		color: #b0895c;
	}
}
