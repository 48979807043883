
//
// list
// --------------------------------------------------

.process-list {
	
	.item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		
		position: relative;
		padding: 7px 20px;
		border: 1px solid #ddd;
		background: #f5f5f5;
		font-size: 13px;
		font-weight: 500;

		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: -16px;
			left: calc(50% - 7px);
			
			@include sprite-2x($ico-arw-bottom);
		}
		
		&:not(:first-child) { margin-top: 20px; }

		&:first-child {
			color: #fff;
			border-color: #01609a;
			background-color: #01609a;

			&:after {
				@include sprite-2x($ico-arw-bottom2);
			}
		}

		&:last-child {
			color: #fff;
			border-color: #b0895c;
			background-color: #b0895c;

			&:after { display: none; }
		}
	}
}