//
// 데이터 레이아웃 테이블
// --------------------------------------------------

/* 데이터 레이아웃 테이블 */
.data-layout-table {
	width: 100%;
	table-layout: fixed;
	line-height: 1.5;
	border: 0;

	&:not(.no-margin) {
		margin-top: 5px;
		margin-bottom: 5px;

		&:first-child { margin-top: 0; }
		&:last-child { margin-bottom: 0; }
	}

	&.auto {
		width: auto;
		margin-left: auto;
		margin-right: auto;
	}
	
	.table {
		width: 100%;
		table-layout: fixed;
		line-height: 1.5;
		background-color: #fff;
		border: 0 none;
	}

	th {
		box-sizing: border-box;
		padding-left: 10px;
		text-align: left;
		vertical-align: top;
		font-weight: 500;
		font-size: 13px;
		color: inherit;
		word-wrap: break-word;
		border: 0 none;
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 2px;
			height: 2px;
			background: #666;
			position: absolute;
			top: .7em;
			left: 2px;
		}
	}

	td {
		box-sizing: border-box;
		text-align: left;
		vertical-align: top;
		font-weight: 400;
		font-size: 13px;
		color: inherit;
		word-wrap: break-word;
		word-break: break-all;
		border: 0 none;
	}

	tr:not(:last-child) {
		th, td {
			padding-bottom: 6px;
		}
	}

	.right {
		text-align: right;
	}
}