/* 요소 간격 */
.el-before { margin-right: 7px; }
.el-after { margin-left: 7px; }

/* 인풋 유형 */
.txt,
.input-box {

	/* 인풋 유효성 아이콘 */
	&.is-valid {
		padding-right: 25px;
		background-image: url('@@img/com/input-valid.png');
		background-repeat: no-repeat;
		background-position: 100% 50%;
		background-size: 19px 11px;
	}
	&.is-invalid {
		padding-right: 25px;
		background-image: url('@@img/com/input-invalid.png');
		background-repeat: no-repeat;
		background-position: 100% 50%;
		background-size: 18px 11px;
	}

	/* date-picker */
	&.date-picker,
	&.ico-date-picker {
		background-repeat: no-repeat;
		background-position: 100% 50%;

		// padding-right: 40px;
		// background-image: url('@@img/com/input-datepicker.png');
		// background-size: 32px 22px;
		// &[disabled] { background-image: url('@@img/com/input-datepicker-disabled.png'); }

		padding-right: 25px;
		background-image: url('@@img/com/input-datepicker-small.png');
		background-size: 21px 15px;
		&[disabled] { background-image: url('@@img/com/input-datepicker-disabled-small.png'); }
	}

	&.date-picker[readonly]:not(.readonly),
	&.ico-date-picker[readonly]:not(.readonly) {
		background-color: #fff;
	}

	/* spinner */
	&.spinner[readonly]:not(.readonly) {
		background-color: #fff;
	}
}

/* 텍스트-버튼 인풋 */
.input-txt-btn {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	font-size: 0;
	white-space: nowrap;

	.txt {
		width: calc(100% - 75px) !important;
		margin-right: 5px;
	}

	.btn {
		width: 70px;
		padding: 0;
	}

	&.file-input {
		.txt[readonly] {
			background-color: #fff;
		}
	}
}

/* 전화 입력 인풋 */
.input-tel-wrap {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	font-size: 0;
	white-space: nowrap;

	.txt,
	.select {
		width: calc((100% - 10px) / 3);
		&:not(:first-child) { margin-left: 5px; }
	}
}

/* 텍스트-버튼 인풋 */
.input-tel-btn {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	font-size: 0;
	white-space: nowrap;

	.input-tel-wrap {
		display: block;
	}

	.btn {
		width: 100%;
		margin-top: 5px;
		padding: 0;
	}
}

/* from-to 타입 */
.input-from-to {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	font-size: 0;
	white-space: nowrap;

	.txt,
	.input-box,
	.select {
		display: inline-block;
		width: calc((100% - 20px) / 2);
	}

	.line {
		display: inline-block;
		width: 20px;
		vertical-align: middle;
		text-align: center;
		font-weight: 300;
		font-size: 13px;
		color: #666;
	}
}

/* 생년월일 입력 인풋 */
.input-birth-ymd {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	font-size: 0;
	white-space: nowrap;

	.txt,
	.select {
		width: calc((100% - 10px) / 3);
		&:not(:first-child) { margin-left: 5px; }
	}
}

/* 이메일 입력 인풋 */
.input-email-wrap {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	font-size: 0;
	white-space: nowrap;

	.txt {
		width: calc((100% - 25px) / 2);
	}

	.select {
		display: block;
		width: 100%;
		margin-top: 5px;
	}

	.at {
		display: inline-block;
		width: 25px;
		line-height: 1;
		vertical-align: middle;
		text-align: center;
		font-weight: 300;
		font-size: 14px;
		color: #666;
		position: relative;
		top: -2px;
	}
}

/* 주소입력 */
.input-address-wrap {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	position: relative;

	.input-address { display: block; }
	.input-address:not(:first-child) { margin-top: 5px; }
	.input-address:first-child {
		padding-right: 75px;
		position: relative;

		.txt { width: 100%; }
		.btn-find-address {
			width: 70px;
			padding: 0;
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}

/* 하위 분류 선택 */
.sub-depth-select {
	display: inline-block;
	width: 100%;
	vertical-align: middle;

	select {
		display: block;
		width: 100%;

		&:not(:first-child) { margin-top: 5px; }
	}
}

/* 옵션 인풋 리스트 */
.option-input-list {
	padding: 10px 5px;
	font-size: 12px;
	border-top: 1px solid #232323;
	border-bottom: 1px solid #ddd;

	.option-item {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&:not(:first-child) { margin-top: 10px; }
	}

	.opt {
		flex-shrink: 0;
		text-align: left;
		overflow: hidden;

		.check {
			width: 100%;

			span {
				display: block;
				padding-right: 5px;
				@include ellipsis;
			}
		}

		&:first-child { flex: 1; }
	}

	.price {
		width: 70px;
		flex-shrink: 0;
		text-align: right;
	}

	.option-grid + & {
		margin-top: -1px;
	}
}

/* 옵션 인풋 리스트2 */
.option-input-list2 {
	padding: 10px 5px;
	border-top: 1px solid #232323;
	border-bottom: 1px solid #ddd;

	.option-item {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&:not(:first-child) { margin-top: 10px; }
	}

	.opt {
		flex: 1;
		text-align: left;
		@include ellipsis;
	}

	.opt2 {
		flex-shrink: 0;
		width: 100px;
		text-align: right;
	}

	.option-grid + & {
		margin-top: -1px;
	}
}

/* 요청사항 입력 textarea */
.comment-input {
	padding: 10px 0 0;
	border-top: 1px solid #232323;
}