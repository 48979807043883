/* datepicker 팝업 */
.datepicker-pop {
	padding: 0;
	padding: safe();

	/* 팝업 메인 */
	.common-pop-body {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		width: 100%;
		// max-width: 400px;
		max-height: 100%;
		vertical-align: middle;
		text-align: left;
		font-size: 14px;
		white-space: normal;
		background: #fff;
		outline: none;
		position: relative;
		z-index: 1001;
	}

	.common-pop-title {
		flex-shrink: 0;
		box-sizing: border-box;
		height: 45px;
		line-height: 45px;
		text-align: center;
		font-weight: 500;
		color: #232323;
		border-bottom: 1px solid #232323;
		position: relative;
	}
	
	.btn-pop-close {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;

		@include afterSpriteIR-2x($ico-datepicker-close, 45px, 45px, absolute);
	}

	.common-pop-content {
		box-sizing: border-box;
		flex: 1;
		padding: 0 20px 20px;
		overflow: auto;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
	}

	.week {
		flex: none;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		width: 100%;
		height: 34px;
		line-height: 34px;
		padding: 0 20px;
		background: #fff;
		border-bottom: 1px solid #ddd;
		position: relative;
		z-index: 1;

		dd {
			flex: 1;
			text-align: center;
			font-weight: 500;
			font-size: 12px;
			height: 33px;
			color: #232323;

			&.sun { color: #b0895c; }
		}
	}

	/* 팝업 하단 버튼 영역 */
	.foot-sticky-action {
		flex-shrink: 0;
	}
}