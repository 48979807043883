//
// jqueryui datepicker 커스텀 스타일
// --------------------------------------------------
/* stylelint-disable */

// 기본 달력 테마 디자인
$datepickerDefaultTheme: '.date-picker-multi';

.ui-datepicker-inline {
	display: inline-block !important;
}

/* DatePicker Container */
#{$datepickerDefaultTheme}.ui-datepicker,
#{$datepickerDefaultTheme} .ui-datepicker
{
	display: none;
	box-sizing: border-box;
	width: 100% !important;
	height: auto;
	vertical-align: top;
	z-index: 200;
	background: #fff;
	overflow: hidden;

	a {
		text-decoration: none;
	}

	/* DatePicker Table */
	table {
		width: 100%;
	}
	.ui-datepicker-header {
		background: #fff;
		color: #e0e0e0;
		font-weight: 500;
		position: relative;
	}
	.ui-datepicker-title {
		box-sizing: border-box;
		padding-top: 25px;
		padding-bottom: 10px;
		text-align: center;
		color: #333;
		font-size: 0;

		.ui-datepicker-year {
			font-size: 16px;
			vertical-align: top;

			&:after {
				content: '.';
				display: inline;
			}
		}

		.ui-datepicker-month {
			vertical-align: top;
			font-size: 16px;
		}
	}

	.ui-datepicker-prev, .ui-datepicker-next {
		display: none;
	}
	thead {
		display: none;
	}
	th {
		text-transform: uppercase;
		font-size: 12px;
		padding: 0 0 5px;
		font-weight: 400;
		color: #666;
	}
	tbody td {
		box-sizing: border-box;
		width: 14.28%;
		height: 38px;
		line-height: 38px;
		text-align: center;
		border: 1px solid #ddd;
		font-size: 12px;
		vertical-align: middle;
	}
	td span, td a {
		display: block;
		height: 100%;
	}
	
	.ui-datepicker-today:not(.selected-start):not(.selected-end):not(.ui-datepicker-current-day) {
		background: #bbb;
		color: #fff;
	}
	
	// 현재선택날짜
	.ui-datepicker-current-day {
		color: #fff;
		background: #006097;
	}

	// 시작날짜
	.selected-start {
		color: #fff !important;
		background: #006097;
	}
	// 종료날짜
	.selected-end:not(.selected-start) {
		color: #fff !important;
		background: #3890c2;
	}
	// 시작-종료 사이 선택된 날짜
	.selected:not(.selected-start):not(.selected-end) {
		background: #d6e9f7;
	}
	
	// 선택불가
	.ui-datepicker-unselectable {
		background: #f5f5f5;
		color: #bbb;
	}

	// 다른 달
	.ui-datepicker-unselectable.ui-datepicker-other-month {
		background: #fff;
	}
	
	// 일요일
	.ui-datepicker-week-end:first-child:not(.selected-start):not(.selected-end) {
		color: #b0895c;
	}
}
