//
// 섹션
// --------------------------------------------------

/* 본문 섹션 마진 */
.next-section {
    margin-top: 30px;
}
/* 본문 섹션 마진2 */
.next-section2 {
    margin-top: 20px;
}
/* 본문 섹션 마진2 */
.next-section3 {
    margin-top: 15px;
}

/* 본문 섹션 마진 - 팝업 */
.next-section-pop {
    margin-top: 20px;
}