//
// 상품 목록
// --------------------------------------------------

/* 상품 목록 - 텍스트형 */
.product-text-list {
	border-top: 1px solid #232323;
	border-bottom: 1px solid #ddd;

	.no-item {
		padding: 50px 0;
		text-align: center;
		font-weight: 300;
		font-size: 16px;
		color: #232323;
	}

	.item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px 0;

		&:not(:first-child) { border-top: 1px solid #ddd; }
	}

	.con-wrap {
		flex: 1;
		font-size: 14px;
		padding: 0 15px 0 10px;
	}

	.title {
		line-height: 1.2;
		font-weight: 500;
		font-size: 14px;
		color: #232323;
		word-wrap: break-word;
	}

	.info {
		margin-top: 5px;
	}

	.price {
		line-height: 1.1;
		font-weight: 500;
		font-size: 16px;
		color: #232323;

		.num { color: #d14e40; }
	}

	.action-area {
		flex-shrink: 0;
		display: flex;
	}

	.list-opt {
		width: 52px;
		margin-right: 5px;
	}

	.btn-list-action {
		width: 72px;
	}

}