//
// 팝업 스타일
// --------------------------------------------------

/* 스크롤 고정 */
.scroll-fixed {
	width: 100%;
	height: 100%;
	position: fixed;
	overflow: hidden;
}

/* 기본 공통 팝업 - 레이어팝업 겸용 */
.common-pop {
	box-sizing: border-box;
	display: inline-block;
	padding: 10px;
	padding: safe(top,10px) safe(right,10px) safe(bottom,10px) safe(left,10px);
	position: relative;
	z-index: 1000;

	// 레이어팝업일때
	&.is-layer {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100% !important;
		height: 100% !important;
		background: rgba(0,0,0,.4);
		position: fixed;
		top: 0;
		left: 0;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}
}

.common-pop-main-msg {
	margin-bottom: 15px;
	line-height: 1.3;
	text-align: center;
	font-weight: 300;
	font-size: 16px;
	color: #232323;
	letter-spacing: -.05em;
}

.common-pop-foot-desc {
	margin-top: 13px;
	font-weight: 300;
	padding-left: 18px;
	text-indent: -18px;
	font-size: 12px;
}