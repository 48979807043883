//
// 그리드 탭메뉴
// --------------------------------------------------

/* 그리드 탭메뉴 */
.tab-grid {
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	margin: 0 -1px 10px;
	overflow: hidden;

	/* 마진 없이 사용 */
	&.no-margin { margin-bottom: 0; }
	
	/* 5분할 */
	&.grid5 {
		.tab {
			width: calc(100% / 5);
			&:nth-child(3) ~ .tab { max-width: auto; }
			&:nth-child(5) ~ .tab { max-width: calc(100% / 5); }
		}
	}

	a {
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding: 5px;
		text-align: center;
		line-height: 1.1;
		font-weight: 300;
		font-size: 12px;
		color: #666;
		text-decoration: none;
		letter-spacing: -.05em;
		border: 1px solid #ddd;
	}

	.tab {
		box-sizing: border-box;
		flex-grow: 1;
		width: calc(100% / 3);
		padding: 0 2px 2px;

		&:nth-child(3) ~ .tab { max-width: calc(100% / 3); }

		&.selected a {
			font-weight: 500;
			color: #fff;
			background: #666;
			border-color: #666;
		}
	}

}